import { PuffLoader } from 'react-spinners';
import { getDateString } from '../../app/utils';
import { useConfigCheck } from '../../app/hooks';
import { components } from '../../app/api/schema';
import { ErrorBox } from '../../components/ErrorBox';
import { useEffect, useMemo, useState } from 'react';
import { Container } from '../../components/Container';
import { Box, Flex, useMediaQuery } from '@chakra-ui/react';
import { MainPageHeader } from '../../components/MainPageHeader';
import { BreadcrumbItemType } from '../../components/BreadCrumbs';
import { pageSizeOptions } from '../../features/tickets/ticket_choices';
import { FilterValues, OptionType } from '../../features/tickets/tickets';
import { PaginatedListLayout } from '../../layout/areas/PaginatedListLayout';
import { ApproveTimeEntryCard } from '../../components/ApproveTimeEntryCard';
import { usePaginatedTimeEntriesQuery } from '../../features/timeEntries/timeEntriesApi';

export type TimeEntriesResource = components["schemas"]["TimeEntry"]

type TicketTypeInterface = {
    ticketTime: string
    nonTicketTime: string
    all: string
}

const ticketTypeMap: TicketTypeInterface = {
    ticketTime: '0',
    nonTicketTime: '1',
    all: '2'
};

export function ApproveSubmittedTimeEntries() {
    const { userProfile } = useConfigCheck()

    const initialState: FilterValues = {
        activity: [],
        customer: null,
        searchterm: '',
        dateRange: [null, null],
        currentPage: 1,
        itemsPerPage: pageSizeOptions[0],
        is_approved: false,
        ticket: null,
        entryType: 'all'
    }
    const [filters, setFilters] = useState<FilterValues>(initialState);
    const [filterApplied, setFilterApplied] = useState(false)
    const [finalFilter, setFinalFilter] = useState<FilterValues>(filters)
    const { entryType, activity, customer, searchterm, dateRange, currentPage, itemsPerPage, ticket, is_approved } = finalFilter;
    const [networkError, setNetworkError] = useState("")

    const url = useMemo(() => {
        const params = new URLSearchParams();
        if (searchterm && searchterm?.trim().length > 0) params.append('search', `${searchterm}`);
        if (ticket) params.append('ticket', `${ticket}`);
        if (customer) params.append('customer', customer?.label);
        if (dateRange[0]) params.append('date_range_after', getDateString(dateRange[0]));
        if (dateRange[1]) params.append('date_range_before', getDateString(dateRange[1]));
        if (currentPage) params.append('page', currentPage.toString());
        if (itemsPerPage) params.append('paginate', itemsPerPage?.value.toString());
        if (entryType) params.append('ticket_type', ticketTypeMap[entryType]);
        params.append('is_approved', `${is_approved}`);
        activity && activity.forEach(activity => {
            params.append('activity', activity.value.toString());
        });

        return params.toString();
    }, [finalFilter])

    const { data, isFetching, refetch, isError, error } = usePaginatedTimeEntriesQuery(url)

    const handlePageChange = (newPage?: number) => {
        setFilters(prev => ({ ...prev, currentPage: newPage || 1 }));
        setFinalFilter(prev => ({ ...prev, currentPage: newPage || 1 }))
    }
    const handlePageSizeChange = (size: OptionType) => {
        setFilters(prev => ({ ...prev, itemsPerPage: size }));
        setFinalFilter(prev => ({ ...prev, itemsPerPage: size }))
    }
    const handleSearch = (data: string) => {
        setFilters(prev => ({ ...prev, searchterm: data }))
        setFinalFilter(prev => ({ ...prev, searchterm: data }))
    }
    const handleApplyFilter = () => {
        const hasDateRange = filters.dateRange[0] !== null;
        const hasEntryType = filters.entryType !== 'all';
        const hasActivity = filters.activity?.length !== 0;
        const hasApprovedStatus = filters.is_approved;
        const hasCustomer = !!filters.customer;

        //check has any filter value changes
        const hasAnyFilterChanges = hasDateRange || hasEntryType || hasActivity || hasApprovedStatus || hasCustomer;

        if (!filterApplied && hasAnyFilterChanges) {
            setFilterApplied(true)
        } else {
            if (filterApplied && !hasAnyFilterChanges) setFilterApplied(false)
        }
        setFinalFilter({ ...filters, currentPage: 1 })
        return hasAnyFilterChanges; //return value to close popover in mobile view
    }
    const handleResetFilter = () => {
        setFilters(initialState)
        setFinalFilter(initialState)
        setFilterApplied(false)
    }
    useEffect(() => {
        if (filterApplied && finalFilter.searchterm) refetch()
    }, [finalFilter])
    useEffect(() => {
        if (error && 'status' in error && error.status === 'FETCH_ERROR') {
            setNetworkError("You appear to have no or limited connectivity. This information will be visible once connected.");
            window.scrollTo({ top: 0, behavior: "smooth" })
        }
    }, [isError])

    //bread crumb items
    const breadCrumbItems: BreadcrumbItemType[] = [
        { label: 'Home', path: '/user/home', icon: 'home' },
        { label: 'Approve Time', isCurrentPage: true }
    ];

    return (
        <Box w="full" mb={10}>
            <MainPageHeader breadCrumbItems={breadCrumbItems} />
            <Container mt={4} direction="column">
                {!isError ?
                    <PaginatedListLayout
                        isLoading={isFetching}
                        title={"Approve Time"}
                        filters={filters}
                        setFilters={setFilters}
                        currentPage={currentPage}
                        totalPage={data?.totalpage}
                        filterApplied={filterApplied}
                        handleSearch={handleSearch}
                        handlePageChange={handlePageChange}
                        handleResetFilter={handleResetFilter}
                        handleApplyFilter={handleApplyFilter}
                        handlePageSizeChange={handlePageSizeChange}
                    >
                        {
                            !isFetching ?

                                (data && data?.timeentries?.length > 0 ? (
                                    data?.timeentries.map((item: TimeEntriesResource, index: number) => (
                                        <ApproveTimeEntryCard key={index} timeEntry={item} />
                                    ))
                                ) : (
                                    userProfile?.role === 'customer' && (
                                        finalFilter?.status?.length === 0 &&
                                        finalFilter.dateRange[0] === null &&
                                        finalFilter.dateRange[1] === null &&
                                        finalFilter.searchterm === ""
                                    ) ?
                                        <Box flex={1} alignContent={"center"}>You do not have any tickets requiring a stamp</Box>
                                        :
                                        <Box flex={1} alignContent={"center"}>No tickets found</Box>
                                ))
                                :
                                <Flex w="full" justifyContent="center" alignItems="center" flex={1}>
                                    <PuffLoader
                                        color="#3D82CE"
                                        size={80}
                                    />
                                </Flex>
                        }
                    </PaginatedListLayout>
                    :
                    <ErrorBox show={isError} error={error} message={networkError} />
                }
            </Container>
        </ Box>
    )
}
