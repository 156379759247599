import { VStack } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import { TerminalAutocomplete } from "./TerminalAutocomplete";
import { LocationAutocomplete } from "./LocationAutocomplete";
import { InputStreak } from "../../components/forms/InputStreak";
import { LsdField } from "../../components/forms/fields/LsdField";
import { LsdInputStreak } from "../../components/forms/LsdInputStreak";
import { LsdPrefixField } from "../../components/forms/fields/LsdPrefixField";

interface ConsigneeFormProps {
  title?: string
}

export function ConsigneeForm({ title }: ConsigneeFormProps) {
  const { watch } = useFormContext()
  const terminalId = watch("consignee.terminal")

  return (
    <>
      <VStack w="full" gap={4} p={[0, 2, 4]}>
        <InputStreak label={title}>
          <TerminalAutocomplete
            label="Company Name"
            name={"consignee.terminal"}
            placeholder="Consignee receiving the shipment"
          />
        </InputStreak>
        <LsdInputStreak label="LSD/Well/Battery">
          <LsdPrefixField label="LSD Prefix" name={"consignee.lsdprefix"} />
          <LsdField label="Well or Battery LSD location" name={"consignee.lsd"} />
        </LsdInputStreak>
        <LocationAutocomplete
          label="Destination Point"
          name={"consignee.location"}
          terminalId={terminalId}
          placeholder="Drop off location"
        />
      </VStack>
    </>
  )
}
