import { getDateString } from "../app/utils";
import { DetailHeading } from "./DetailHeading";
import { KnownIcon } from "./widgets/KnownIcon";
import { Box, Card, CardBody, Flex, HStack, Stack, StackDivider, Text, useMediaQuery, VStack } from "@chakra-ui/react";

export default function TimeEntryCard({ timeEntry }: any) {
    const [isBreakpoint] = useMediaQuery("(min-width: 1200px)")

    return (
        < >
            {
                isBreakpoint ?
                    <Card w="full" >
                        <CardBody p={{ base: 2, lg: 5 }}>
                            <Stack p={2} divider={<StackDivider />} spacing='4' color={"#0E1628"}>
                                <Box>
                                    <DetailHeading title={`${getDateString(timeEntry.date)}`} />
                                    <DetailHeading title={timeEntry.total_hours} fontWeight={400} fontSize={"14px"} color={"#666E82"} />
                                </Box>
                                <Box width="100%">
                                    <HStack
                                        mb={4}
                                        fontSize={[12, 14, 16]}
                                        color="#455360"
                                        textAlign="left"
                                        spacing={0}
                                        divider={<StackDivider />}
                                    >
                                        <Text flexBasis="30%">Task</Text>
                                        <Text flexBasis="40%" px={{ base: 2, sm: 5 }}>Company</Text>
                                        <Text flexBasis="30%" px={{ base: 2, sm: 5 }}>Time</Text>
                                    </HStack>

                                    <VStack align="stretch" spacing={4}>
                                        {timeEntry.tasks.map((entry: any, index: number) => (
                                            <HStack key={index} alignItems="flex-start" color={"#0E1628"} spacing={0} divider={<StackDivider />}>
                                                <HStack flexBasis="30%" my={4}>
                                                    <KnownIcon name="activity" display={"inline"} boxSize={{ base: "16px", md: "20px", lg: "24px" }} />
                                                    <Text fontSize={[12, 14, 16]}>{entry.Task}</Text>
                                                </HStack>
                                                <HStack flexBasis="40%" alignItems="flex-start" px={{ base: 2, sm: 5 }} my={4}>
                                                    <KnownIcon name="customer" display={"inline"} boxSize={{ base: "16px", md: "20px", lg: "24px" }} />
                                                    <Box w="full" wordBreak="break-word" whiteSpace="normal" overflow="hidden">
                                                        <Text
                                                            fontWeight={500}
                                                            noOfLines={2}
                                                            fontSize={[12, 14, 16]}
                                                        >
                                                            {entry.Customer}
                                                        </Text>
                                                    </Box>
                                                </HStack>
                                                <HStack flexBasis="30%" alignItems="flex-start" px={{ base: 2, sm: 5 }} my={4}>
                                                    <KnownIcon name="clock" display={"inline"} boxSize={{ base: "16px", md: "20px", lg: "24px" }} />
                                                    <Text fontSize={[12, 14, 16]}>{entry.Time}</Text>
                                                </HStack>
                                            </HStack>
                                        ))}
                                    </VStack>
                                </Box>

                            </Stack>
                        </CardBody>
                    </Card > :

                    <Card w="full">
                        <CardBody p={{ base: 2, lg: 5 }}>
                            <Stack p={2} divider={<StackDivider />} spacing='4' color={"#0E1628"}>
                                <HStack justifyContent={'space-between'}>
                                    <Text fontWeight={600}>{`${getDateString(timeEntry.date)}`}</Text>
                                    <HStack alignItems="flex-start">
                                        <KnownIcon name="clock" display={"inline"} boxSize={{ base: "16px", md: "20px", lg: "24px" }} />
                                        <Text fontSize={[12, 14, 16]}>{timeEntry.total_hours}</Text>
                                    </HStack>
                                </HStack>
                                {timeEntry.tasks.map((entry: any, index: number) => (
                                    <VStack key={index} alignItems='stretch'>
                                        <HStack justifyContent={'space-between'} gap={0} alignItems={'flex-start'}>
                                            <HStack flexBasis={["60%", "70%"]} alignItems="flex-start" >
                                                <KnownIcon name="customer" display={"inline"} boxSize={{ base: "16px", md: "20px", lg: "24px" }} />
                                                <Box w="full" wordBreak="break-word" whiteSpace="normal" overflow="hidden">
                                                    <Text
                                                        fontWeight={500}
                                                        noOfLines={2}
                                                        fontSize={[12, 14, 16]}
                                                    >
                                                        {entry.Customer}
                                                    </Text>
                                                </Box>
                                            </HStack>
                                            <Flex w='fit-content' p={1} gap={'10px'} fontSize={[12, 14]} borderRadius={"40px"} bgColor={'#EBEEFF'} justifyContent={"center"} alignItems={"center"}>
                                                <KnownIcon name="clock" display={"inline"} boxSize={{ base: "16px", md: "20px", lg: "24px" }} />
                                                <Text noOfLines={1} isTruncated>{entry.Time}</Text>
                                            </Flex>
                                        </HStack>
                                        <HStack >
                                            <KnownIcon name="activity" display={"inline"} boxSize={{ base: "16px", md: "20px", lg: "24px" }} />
                                            <Text fontSize={[12, 14, 16]}>{entry.Task}</Text>
                                        </HStack>
                                    </VStack>
                                ))}
                            </Stack>
                        </CardBody>
                    </Card>
            }
        </ >
    )
}
