import { useTrait } from "../../app/hooks";
import { selectEquipmentById } from "./equipmentsSlice";

interface EquipmentTraitProps extends TraitProps { }

export function EquipmentTrait({ id }: EquipmentTraitProps) {
  const result = useTrait(
    (equipment) => `${equipment.unit} (${equipment?.category?.name})`,
    id,
    selectEquipmentById,
  )
  const { trait = '', isNew = false } = result || {};

  return trait
}
