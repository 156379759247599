import { ChangeEvent, Fragment } from 'react';
import { useConfigCheck } from '../app/hooks';
import { KnownIcon } from './widgets/KnownIcon';
import { TimeEntriesResource } from '../pages/user/ApproveSubmittedTimeEntries';
import { formatMinutesAsHours, formatTimeOnly, getDateString } from '../app/utils';
import { usePartialUpdateTimeEntryMutation } from '../features/timeEntries/timeEntriesApi';
import { Box, Card, CardBody, Flex, HStack, Spacer, Stack, StackDivider, Switch, Text } from '@chakra-ui/react';

interface ApproveTimeEntryCardProps {
    timeEntry: TimeEntriesResource
}

export function ApproveTimeEntryCard({ timeEntry }: ApproveTimeEntryCardProps) {
    const { isOnline } = useConfigCheck()
    const [partialUpdateTimeEntry] = usePartialUpdateTimeEntryMutation()

    const handleApproveTime = async (e: ChangeEvent<HTMLInputElement>, id: number) => {
        const { checked } = e.target
        partialUpdateTimeEntry({ id, is_approved: checked })
    }

    return (
        <Fragment >
            <Card w="full" >
                <CardBody p={{ base: 2, lg: 5 }} color={"#0E1628"}>
                    <Stack p={2} divider={<StackDivider />} spacing='4' color={"#0E1628"}>
                        <HStack justifyContent={"space-between"} alignItems={"center"} gap={5}>
                            <Flex gap={8} flex={1} alignItems={"center"}>
                                <Text minW={"30%"} fontSize={{ base: "12px", sm: "16px" }} fontWeight={600}>{typeof timeEntry?.ticket === "number" ? `#${timeEntry.ticket}` : ""}</Text>
                                <Text flex={1} fontWeight={600} fontSize={{ base: "12px", sm: "18px" }} whiteSpace="normal" wordBreak={"break-word"} textOverflow="ellipsis" overflow="hidden" noOfLines={2}  >
                                    {timeEntry?.driver || "--"}
                                </Text>
                            </Flex>
                            <Flex fontSize={{ base: "12px", sm: "16px" }} color={"#455360"} gap={[2, 3, 5]} alignItems="center">
                                Approve:
                                <Switch
                                    sx={{
                                        '.chakra-switch__track': {
                                            bg: 'gray.300', // background when off
                                        },
                                        '.chakra-switch__track[data-checked]': {
                                            bg: '#3455FF', // custom background color when on
                                        },
                                    }}
                                    isChecked={timeEntry.is_approved}
                                    onChange={(e) => handleApproveTime(e, timeEntry.id)} display="inline"
                                    isDisabled={!isOnline}
                                    size={{ base: "sm", md: "md" }}
                                />
                            </Flex>

                        </HStack>
                        <HStack w="full" gap={5} >
                            <Flex
                                alignItems="center"
                                columnGap={4}
                                rowGap={2}
                                wrap="wrap"
                                justifyContent="space-between"
                                flex="1"
                            >
                                <Box>
                                    <Text
                                        whiteSpace="nowrap"
                                        overflow="hidden"
                                        maxWidth={{ base: "160px", sm: "250px", md: "250px", lg: "350px" }}
                                        fontSize={{ base: "14px", sm: "16px", lg: "18px" }} fontWeight={500}
                                        isTruncated
                                    >
                                        {timeEntry?.customer}
                                    </Text>
                                    <Text fontSize={"14px"} fontWeight={400}> {timeEntry.activity.name}</Text>
                                </Box>
                                <Flex gap={5} fontSize={{ base: "12px", lg: "16px" }} >
                                    <Flex gap={{ base: "6px", lg: 3 }} alignItems={"center"}>
                                        <KnownIcon name="clock" boxSize={{ base: "16px", md: "20px", lg: "24px" }} />
                                        <Text>{`${formatMinutesAsHours(timeEntry?.minutes)} Hours`}</Text>
                                    </Flex>
                                    <Flex gap={{ base: "6px", lg: 3 }} alignItems={"center"}>
                                        <KnownIcon name="calendar" boxSize={{ base: "16px", md: "20px", lg: "24px" }} />
                                        <Text>{`${getDateString(timeEntry?.finish ?? '')}`}</Text>
                                    </Flex>
                                </Flex>
                            </Flex>
                        </HStack>
                        <HStack>
                            <Box w="fit-content" minW={"100px"}>
                                <Text color={"#666E82"} fontSize={{ base: 12, md: 14 }}>Start Time</Text>
                                <Flex gap={{ base: "6px", lg: 3 }} alignItems={"center"}>
                                    <KnownIcon name="clock" boxSize={{ base: "16px", md: "20px", lg: "24px" }} />
                                    <Text fontSize={{ base: "12px", sm: "14px", lg: "16px" }} >{formatTimeOnly(timeEntry?.start) || "--"} </Text>
                                </Flex>
                            </Box>
                            <Spacer borderBottom={"2px dashed #9F9F9F"} />
                            <Box w="fit-content" minW={"100px"}>
                                <Text color={"#666E82"} fontSize={{ base: 12, md: 14 }}>End Time</Text>
                                <Flex gap={{ base: "6px", lg: 3 }} alignItems={"center"}>
                                    <KnownIcon name="clock" boxSize={{ base: "16px", md: "20px", lg: "24px" }} />
                                    <Text fontSize={{ base: "12px", sm: "14px", lg: "16px" }}  >{formatTimeOnly(timeEntry?.finish) || "--"} </Text>
                                </Flex>
                            </Box>
                        </HStack>
                    </Stack>
                </CardBody>
            </Card>
        </Fragment >
    )
}
