import { Box, useMediaQuery } from "@chakra-ui/react";
import { DroppOffValues } from "./tickets";
import { forwardRef, useEffect } from "react";
import { DropoffPanelForm } from "./DropoffPanelForm";
import { Container } from "../../components/Container";
import { EditForm } from "../../components/forms/EditForm";
import { PanelWrapper } from "../../components/PanelWrapper";
import { OverlayLoader } from "../../components/OverlayLoader";
import { useAppDispatch, useAppSelector, useCustomToast } from "../../app/hooks";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { CustomButton } from "../../components/forms/CustomButton";
import { WrapperProps } from "../../components/TicketDetailWrapper";
import { saveDraftDroppOff, selectDroppOff } from "../draft/draftSlice";
import { selectTicketDropOffDetail, updateTicketDropOffDetail } from "./ticketsSlice";

interface DropOffPanelProps extends WrapperProps {
    scrollTo?(data: string): void
}

export const DropOffPanel = forwardRef<HTMLDivElement, DropOffPanelProps>(
    function DropOffPanel({ resourceid, isdraft, scrollTo }: DropOffPanelProps, ref) {
        const dispatch = useAppDispatch()
        const { showToast } = useCustomToast();
        const [isSmallScreen] = useMediaQuery("(max-width: 468px)")

        //Selector based on draft or ticket
        const selectResource = isdraft ?
            useAppSelector(selectDroppOff(Number(resourceid)))
            : useAppSelector(selectTicketDropOffDetail(Number(resourceid))) || {}

        const methods = useForm({ defaultValues: selectResource })
        const { control, handleSubmit, formState: { isSubmitting } } = methods

        // Watch form values to detect changes
        const watchedValues = useWatch({
            control,
            defaultValue: selectResource,
        })
        const hasChanged = JSON.stringify(watchedValues) !== JSON.stringify(selectResource)

        const handleSaveResource = async (data: any) => {
            try {
                if (isdraft) {
                    dispatch(saveDraftDroppOff({ id: resourceid, ...data }));
                } else {
                    await dispatch(updateTicketDropOffDetail({ id: resourceid, ...data })).unwrap();
                }
                // Scroll after the operation completes
                scrollTo?.('driverHoursForm');
            } catch (error) {
                console.error("Action failed:", error);
                showToast({
                    status: 'error',
                    description: "Something went wrong",
                });
            }
        };
        //auto save handler for draft page
        useEffect(() => {
            if (isdraft) dispatch(saveDraftDroppOff({ id: Number(resourceid), ...watchedValues } as DroppOffValues));
        }, [watchedValues, resourceid, dispatch, isdraft]);

        return (
            <Box w="full" ref={ref}>
                {isSubmitting && <OverlayLoader />}
                <Container condensed={!isSmallScreen}>
                    <FormProvider {...methods}>
                        <EditForm onSave={handleSubmit(handleSaveResource)}>
                            <PanelWrapper title="Unload">
                                {hasChanged && !isdraft && < CustomButton title="Save" type="submit" variant="outline" w="80px" />}
                                <DropoffPanelForm />
                            </PanelWrapper>
                        </EditForm>
                    </FormProvider>
                </Container >
            </Box>
        )
    })
