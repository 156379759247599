import { PuffLoader } from "react-spinners";
import { Box, Flex } from "@chakra-ui/react";
import { getDateString } from "../../app/utils";
import { useConfigCheck } from "../../app/hooks";
import { components } from "../../app/api/schema";
import { useState, useMemo, useEffect } from "react";
import { ErrorBox } from "../../components/ErrorBox";
import { Container } from "../../components/Container";
import { TicketCard } from "../../components/TicketCard";
import { PageBanner } from "../../components/PageBanner";
import { MainPageHeader } from "../../components/MainPageHeader";
import { BreadcrumbItemType } from "../../components/BreadCrumbs";
import { pageSizeOptions } from "../../features/tickets/ticket_choices";
import { FilterValues, OptionType } from "../../features/tickets/tickets";
import { PaginatedListLayout } from "../../layout/areas/PaginatedListLayout";
import { useListingPaginatedTicketDataQuery } from "../../features/tickets/ticketsApi";

type DateRange = [Date | null, Date | null];
export type TicketData = components["schemas"]["Paginated_Ticket"];

export function TicketsPage() {
    const { userProfile } = useConfigCheck();
    const [networkError, setNetworkError] = useState<string>("");
    const initialState = {
        status: [],
        searchterm: "",
        dateRange: [null, null] as DateRange,
        currentPage: 1,
        itemsPerPage: pageSizeOptions[0],
    };

    const [filters, setFilters] = useState<FilterValues>(initialState);
    const [finalFilter, setFinalFilter] = useState<FilterValues>(filters);
    const [filterApplied, setFilterApplied] = useState(false);
    const { status, searchterm, dateRange, currentPage, itemsPerPage } = finalFilter;

    const url = useMemo(() => {
        const params = new URLSearchParams();
        if (searchterm && searchterm?.trim().length > 0) params.append('search', `${searchterm}`);
        if (dateRange[0]) params.append('date_range_after', getDateString(dateRange[0]));
        if (dateRange[1]) params.append('date_range_before', getDateString(dateRange[1]));
        if (currentPage) params.append('page', currentPage.toString());
        if (itemsPerPage) params.append('paginate', itemsPerPage?.value.toString());
        status && status.forEach(status => {
            params.append('status', status.value.toString());
        });
        return params.toString();
    }, [finalFilter]);

    const { data, isFetching, error, isError, refetch, isSuccess } = useListingPaginatedTicketDataQuery(url);

    const handlePageChange = (newPage?: number) => {
        setFilters(prev => ({ ...prev, currentPage: newPage || 1 }));
        setFinalFilter(prev => ({ ...prev, currentPage: newPage || 1 }))
    };
    const handlePageSizeChange = (size: OptionType) => {
        setFilters(prev => ({ ...prev, itemsPerPage: size }));
        setFinalFilter(prev => ({ ...prev, itemsPerPage: size }))
    };
    const handleApplyFilter = () => {
        const hasDateRange = filters.dateRange[0] !== null;
        const hasStatus = filters.status?.length != 0;

        //check has any filter value changes
        const hasAnyFilterChanges = hasDateRange || hasStatus;

        if (!filterApplied && hasAnyFilterChanges) {
            setFilterApplied(true)
        } else {
            if (filterApplied && !hasAnyFilterChanges) setFilterApplied(false)
        }
        setFinalFilter({ ...filters, currentPage: 1 })
        return hasAnyFilterChanges; //return value to close popover in mobile view
    }
    const handleResetFilter = () => {
        setFilters(initialState)
        setFinalFilter(initialState)
        setFilterApplied(false)
    };

    //refetch data if filter is applied
    useEffect(() => {
        if (filterApplied && finalFilter.searchterm) refetch();
    }, [finalFilter]);

    //set error if there is network issue
    useEffect(() => {
        if (error && 'status' in error && error.status === 'FETCH_ERROR') {
            setNetworkError("You appear to have no or limited connectivity. This information will be visible once connected.");
            window.scrollTo({ top: 0, behavior: "smooth" })
        } else if (isSuccess && data) {
            setNetworkError("");
        }
    }, [isError, isSuccess, data]);

    //search handler
    const handleSearch = (data: string) => {
        setFilters(prev => ({ ...prev, searchterm: data }))
        setFinalFilter(prev => ({ ...prev, searchterm: data }))
    };

    const title = data ? `${data?.total} Ticket Results` : '';
    const breadCrumbItems: BreadcrumbItemType[] = [
        { label: 'Home', path: '/user/home', icon: 'home' },
        { label: 'View All Tickets', isCurrentPage: true }
    ];

    return (
        <>
            {
                userProfile?.role === "customer" ?
                    <PageBanner title="Ticket" helper="Summary" /> :
                    <MainPageHeader breadCrumbItems={breadCrumbItems} />
            }
            {
                !isError ?
                    <Box w="full" mb={10}>
                        <Container mt={2} >
                            <PaginatedListLayout
                                isLoading={isFetching}
                                title={title}
                                filters={filters}
                                setFilters={setFilters}
                                currentPage={currentPage}
                                totalPage={data?.totalpage}
                                filterApplied={filterApplied}
                                handleSearch={handleSearch}
                                handlePageChange={handlePageChange}
                                handleResetFilter={handleResetFilter}
                                handleApplyFilter={handleApplyFilter}
                                handlePageSizeChange={handlePageSizeChange}
                            >
                                {!isFetching ?
                                    (data && data?.tickets?.length > 0 ? (
                                        data?.tickets.map((item: TicketData) => (
                                            <TicketCard key={item.id} ticket={item} />
                                        ))
                                    ) : (
                                        userProfile?.role === 'customer' && (
                                            finalFilter?.status?.length === 0 &&
                                            finalFilter.dateRange[0] === null &&
                                            finalFilter.dateRange[1] === null &&
                                            finalFilter.searchterm === ""
                                        ) ?
                                            <Box flex={1} alignContent={"center"}>You do not have any tickets requiring a stamp</Box>
                                            :
                                            <Box flex={1} alignContent={"center"}>No tickets found</Box>
                                    ))
                                    :
                                    <Flex w="full" justifyContent="center" alignItems="center" flex={1}>
                                        <PuffLoader
                                            color="#3D82CE"
                                            size={80}
                                        />
                                    </Flex>
                                }
                            </PaginatedListLayout>
                        </Container>
                    </ Box>
                    :
                    <ErrorBox show={isError} error={error} message={networkError} />
            }
        </>
    )
}

