import { FieldProps } from "../../../forms"
import { SelectField } from "./SelectField"


interface SubsidiaryClassSelectProps extends FieldProps { }

export function SubsidiaryClassSelect(props: SubsidiaryClassSelectProps) {
    const options = ["I", "II", "III"].map((item) => ({ value: item, label: item }))
    return <SelectField  {...props} options={options} />
}
