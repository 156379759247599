import { useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { ErrorBox } from "../../components/ErrorBox";
import { useNavigate, useParams } from "react-router-dom";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { OverlayLoader } from "../../components/OverlayLoader";
import { BasicPanel } from "../../features/tickets/BasicPanel";
import { MainPageHeader } from "../../components/MainPageHeader";
import { PickUpPanel } from "../../features/tickets/PickUpPanel";
import { BreadcrumbItemType } from "../../components/BreadCrumbs";
import { DropOffPanel } from "../../features/tickets/DropOffPanel";
import { ProductsPanel } from "../../features/draft/ProductsPanel";
import { CustomButton } from "../../components/forms/CustomButton";
import { useDestroyAttachmentsMutation } from "../../features/aws/awsApi";
import { TicketDetailWrapper } from "../../components/TicketDetailWrapper";
import { useAppSelector, useConfigCheck, useScrollTo } from "../../app/hooks";
import { DriverHoursPanel } from "../../features/timeEntries/DriverHoursPanel";
import { TicketDetailPageHeader } from "../../components/TicketDetailPageHeader";
import { AdditionalItemsPanel } from "../../features/draft/AdditionalItemsPanel";
import { cleanLocalEntries, } from "../../features/timeEntries/localEntriesSlice";
import { DraftLocationsPanel } from "../../features/locations/DraftLocationsPanel";
import { AttachmentDropZone } from "../../components/forms/fields/AttachmentDropZone";
import { bindDraftNumber, createTicketAfterDraft, discardDraft, selectDraftNumber } from "../../features/draft/draftSlice";
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, ButtonGroup, HStack } from "@chakra-ui/react";

export function DraftPage() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const cancelRef = useRef(null);
    const hasGeneratedNumber = useRef(false); // To track if the number has already been generated
    const { isOnline } = useConfigCheck();
    const [setRef, scrollTo, RefMap] = useScrollTo();
    const draftIndex: number = id !== undefined ? parseInt(id) - 1 : 0;
    const draftNumber = useAppSelector(selectDraftNumber(draftIndex));    //get draft number

    const [show, setShow] = useState<boolean>(false);
    const [isDiscardDialogOpen, setIsDiscardDialogOpen] = useState(false);
    const [formIsSubmitting, setFormIsSubmiting] = useState<boolean>(false);
    const [draftErrorMessage, setDraftErrorMessage] = useState<string[]>([]);
    const [destroyAttachments] = useDestroyAttachmentsMutation();

    // const nanoid = customAlphabet("1234567890", 6)
    //create and bind unique id while creating a draft 
    useEffect(() => {
        if (!hasGeneratedNumber.current && !draftNumber) {
            // Generate the random number once
            const min = 200000;
            const max = 999999;
            const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
            dispatch(bindDraftNumber({ id: draftIndex, draftuniqueid: randomNumber }));
            hasGeneratedNumber.current = true;
        }
    }, []);

    const triggerChildValidation = async () => {
        let isValid = true;
        // Iterate over the keys of the RefMap object
        for (const formRefKey in RefMap) {
            const currentRef = RefMap[formRefKey]
            if (currentRef && currentRef.triggerValidation) {
                const isChildValid = await currentRef.triggerValidation();
                if (!isChildValid) {
                    isValid = false;
                    break;  // Stop validation if any form fails
                }
            }
        }
        return isValid;
    };

    //Submit draft handler
    const handleSubmitDraft = async () => {
        const isValid = await triggerChildValidation();
        if (isValid) {
            setFormIsSubmiting(true)
            try {
                await dispatch(createTicketAfterDraft(draftIndex) as any).unwrap()
                navigate("/user/home")
            } catch (error) {
                const err = error as FetchBaseQueryError;
                const errors = Object.values(err?.data as object).map(({ message }) => message)
                setShow(true)
                setDraftErrorMessage(errors)
                window.scrollTo({ top: 0, behavior: "smooth" })
            } finally {
                setFormIsSubmiting(false)
            }
        } else {
            window.scrollTo({ top: 0, behavior: "smooth" })
        }
    }

    //Discard draft handler
    const handleDiscard = () => {
        setIsDiscardDialogOpen(true)
    }

    const handleConfirmDiscard = () => {
        console.log("Discarding draft...")
        dispatch(discardDraft({ draftIndex: draftIndex }))
        dispatch(cleanLocalEntries({ draftNumber: draftNumber }))
        navigate("/user/home")
        setIsDiscardDialogOpen(false)
        /* remove files related to this draft from aws */
        draftNumber && destroyAttachments(draftNumber)
    }

    const handleCancelDiscard = () => {
        setIsDiscardDialogOpen(false)
    }

    //breadcrumb for draft page
    const breadCrumbItems: BreadcrumbItemType[] = [
        { label: 'Home', path: '/user/home', icon: 'home' },
        { label: `#${draftNumber}`, isCurrentPage: true },
    ];

    return (
        <>
            <MainPageHeader breadCrumbItems={breadCrumbItems} />
            {formIsSubmitting && <OverlayLoader />}
            <ErrorBox show={show} message={draftErrorMessage} />
            <TicketDetailWrapper >
                <TicketDetailPageHeader />
                <BasicPanel scrollTo={scrollTo} ref={setRef('BasicDetailForm')} />
                <DraftLocationsPanel scrollTo={scrollTo} ref={setRef('LocationsForm')} />
                <ProductsPanel ref={setRef('productForm')} />
                <PickUpPanel ref={setRef('pickUpForm')} scrollTo={scrollTo} />
                <DropOffPanel ref={setRef('dropOffForm')} scrollTo={scrollTo} />
                <DriverHoursPanel ref={setRef('driverHoursForm')} />
                <AdditionalItemsPanel ref={setRef('servicesForm')} />
                <AttachmentDropZone />
                <HStack w="full" justifyContent={"center"} alignItems={"center"}>
                    <CustomButton w={[120, 160]} title="Submit" onClick={handleSubmitDraft} isDisabled={!isOnline} />
                    <CustomButton w={[120, 160]} title="Discard" variant="outline" colorScheme="red" onClick={handleDiscard} />
                </HStack>
            </TicketDetailWrapper>

            <AlertDialog
                isOpen={isDiscardDialogOpen}
                leastDestructiveRef={cancelRef}
                onClose={handleCancelDiscard}
                size={["xs", "sm"]}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Discard Draft
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure you want to discard this draft? This action cannot be
                            undone.
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <ButtonGroup>
                                <CustomButton title="Cancel" onClick={handleCancelDiscard} />
                                <CustomButton title="Discard" variant="outline" colorScheme="red" onClick={handleConfirmDiscard} />
                            </ButtonGroup>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}

