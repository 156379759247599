import { useDispatch } from 'react-redux';
import { useAppSelector } from '../app/hooks';
import { useLocation, useParams } from 'react-router-dom';
import { TicketResource } from '../pages/user/TicketDetailPage';
import { Draft, selectDraft } from '../features/draft/draftSlice';
import { selectTicketById } from '../features/tickets/ticketsSlice';
import { Children, ReactElement, ReactNode, cloneElement, isValidElement } from 'react';

export interface TicketDetailWrapperProps {
    children?: ReactNode | ReactNode[]
}

export interface WrapperProps {
    dataresource?: TicketResource | Draft | null;
    isdraft?: boolean
    resourceid?: string | number
    draftuniqueid?: number | null
}

export const TicketDetailWrapper = ({ children }: TicketDetailWrapperProps) => {
    const { id } = useParams<{ id: string }>();
    const draftIndex: number = id !== undefined ? parseInt(id) - 1 : 0

    //check whether it is draft page or not
    const pathname = useLocation().pathname
    const isdraft = pathname.includes("user/draft");

    //get resource data based on draft or ticket
    const resourceid = isdraft ? draftIndex : id;
    const dataresource = resourceid !== null && resourceid !== undefined ? (isdraft
        ? useAppSelector(selectDraft(Number(resourceid)))
        : useAppSelector((state) => selectTicketById(state, Number(resourceid))))
        : null;

    return (
        <>
            {Children.map(children, (child) => {
                if (isValidElement(child)) {
                    return cloneElement(child as ReactElement<WrapperProps>, { resourceid, dataresource, isdraft });
                }
                return child;
            })}
        </>
    );
};
