import Select from "react-select";
import { PuffLoader } from 'react-spinners';
import { ListView } from '../views/ListView';
import { useLocation } from 'react-router-dom';
import { Dispatch, SetStateAction } from 'react';
import { useConfigCheck } from '../../app/hooks';
import { SearchInput } from '../../components/SearchBox';
import ResponsivePagination from 'react-responsive-pagination';
import { DetailHeading } from '../../components/DetailHeading';
import { KnownIcon } from '../../components/widgets/KnownIcon';
import { pageSizeOptions } from '../../features/tickets/ticket_choices';
import { FilterValues, OptionType } from '../../features/tickets/tickets';
import { Box, Button, Flex, HStack, useDisclosure, VStack } from '@chakra-ui/react';


interface PaginatedListLayoutProps {
    isLoading?: boolean
    title: string
    filters: FilterValues
    filterApplied: boolean
    setFilters?: Dispatch<SetStateAction<FilterValues>>;
    handleApplyFilter?(): void
    handleResetFilter?(): void
    handlePageChange(page: number): void
    handlePageSizeChange(data: OptionType): void
    handleSearch?(data: string): void
    currentPage?: number
    totalPage?: number
    timeEntryTotal?: string
    children: React.ReactNode | React.ReactNode[]
}


export function PaginatedListLayout({
    isLoading = false,
    title,
    filters,
    setFilters = () => { },
    handleApplyFilter,
    handleResetFilter,
    filterApplied,
    handlePageChange,
    handlePageSizeChange,
    handleSearch,
    currentPage,
    totalPage,
    children,
}: PaginatedListLayoutProps) {
    const { isOnline } = useConfigCheck()
    const pathName = useLocation().pathname
    const { isOpen, onClose, onOpen } = useDisclosure()
    const { userProfile: { role } = {} } = useConfigCheck()

    const isApproveTime = (role !== "customer" && role !== "driver") && pathName.includes("/user/approve-time-entries");
    const getSearPlaceholders = (role: string, isApproveTime: boolean) => {
        if (isApproveTime) return ["Search by driver name", "Search by ticket number"];
        if (role === "customer") return ["Search by ticket number"]
        if (role !== "customer" && role !== "driver") return ["Search by driver name", "Search by customer name", "Search by ticket number"];
        return ["Search by customer name"];
    };

    return (
        <HStack w={"full"} alignItems={"flex-start"}>
            <ListView
                isOpen={isOpen}     // have to open drawer for smaller devices
                onClose={onClose}
                filters={filters}
                setFilters={setFilters}
                filterApplied={filterApplied}
                handleApplyFilter={handleApplyFilter}
                handleResetFilter={handleResetFilter}
            />
            <VStack w="full">
                <HStack
                    w="full"
                    rowGap={4}
                    justifyContent={"space-between"}
                    alignItems={{ base: "flex-start", md: "center" }}
                    flexDirection={{ base: "column-reverse", md: "row" }}
                >
                    <HStack w={"full"} justifyContent={"space-between"} alignItems="center" >
                        <DetailHeading title={title} fontSize={{ base: "16px", md: "20px" }} />
                        <Button p={2} display={{ base: "flex", md: "none" }} bgColor={"#F8F8FB"} onClick={() => onOpen()} isDisabled={isOnline ? false : true}>
                            <KnownIcon name='filter' cursor={"pointer"} />
                        </Button>
                    </HStack>
                    {
                        handleSearch &&
                        <SearchInput
                            handleSearch={handleSearch}
                            placeholders={role && getSearPlaceholders(role, isApproveTime)}
                        />
                    }
                </HStack>
                <VStack w={"full"} h={"full"} spacing={5} minH={"400px"}  >
                    {
                        !isLoading ?
                            children
                            :
                            <Flex w="full" justifyContent="center" alignItems="center" flex={1}>
                                <PuffLoader
                                    color="#3D82CE"
                                    size={80}
                                />
                            </Flex>
                    }
                </VStack>
                {
                    totalPage && totalPage > 1 && currentPage && isOnline &&
                    <HStack w="full" mt={5} mb={5} justifyContent={"space-between"} >
                        <Box w="120px">
                            <Select
                                value={filters.itemsPerPage}
                                options={pageSizeOptions}
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        backgroundColor: "#fff",
                                    }),
                                    option: (baseStyles, state) => ({
                                        ...baseStyles,
                                        fontSize: "14px",
                                        color: "#7B8389",
                                        backgroundColor: state.isSelected ? "#EBEEFF" : "#fff",
                                        ":hover": {
                                            backgroundColor: !state.isSelected ? "#DDEFFF" : undefined
                                        }
                                    }),
                                    singleValue: (baseStyles) => ({
                                        ...baseStyles,
                                        fontSize: "14px",
                                        color: "#7B8389", // Color for the selected option when dropdown is closed
                                    }),
                                }}
                                components={{
                                    DropdownIndicator: null,
                                    IndicatorSeparator: null,
                                }}
                                onChange={(value) => value && handlePageSizeChange(value)}
                                menuPortalTarget={document.body} menuPosition={'fixed'}
                            />


                        </Box>
                        {
                            isOnline &&
                            <Box>
                                <ResponsivePagination
                                    total={totalPage || 1}
                                    current={currentPage || 1}
                                    onPageChange={page => handlePageChange(page)}
                                    previousLabel="‹" nextLabel="›"
                                />
                            </Box>
                        }
                    </HStack>
                }
            </VStack>
        </ HStack>
    )
}
