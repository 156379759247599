import { bolmintApi } from "../../app/api"
import { operations } from "../../app/api/schema"

export const customersApi = bolmintApi.injectEndpoints({
  endpoints: (builder) => ({
    listCustomers: builder.query<
      operations["customers_list"]["responses"]["200"]["content"]["application/json"],
      void
    >({
      query: () => ({
        url: "/customers/",
      }),
      providesTags: ["customers"]
    }),
    createCustomer: builder.mutation<
      operations["customers_create"]["responses"]["201"]["content"]["application/json"],
      Partial<
        operations["customers_create"]["requestBody"]["content"]["application/json"]
      >
    >({
      query: (resource) => ({
        url: "/customers/",
        method: "POST",
        body: resource,
      }),
    }),
    retrieveCustomer: builder.query<
      operations["customers_retrieve"]["responses"]["200"]["content"]["application/json"],
      number
    >({
      query: (customerId) => ({
        url: `/customers/${customerId}/`,
      }),
    }),
    updateCustomer: builder.mutation<
      operations["customers_update"]["responses"]["200"]["content"]["application/json"],
      operations["customers_update"]["requestBody"]["content"]["application/json"]
    >({
      query: (resource) => ({
        url: `/customers/${resource.id}/`,
        method: "PUT",
        body: resource,
      }),
    }),
    partialUpdateCustomer: builder.mutation<
      operations["customers_partial_update"]["responses"]["200"]["content"]["application/json"],
      operations["customers_partial_update"]["requestBody"]["content"]["application/json"]
    >({
      query: (resource) => ({
        url: `/customers/${resource.id}/`,
        method: "PATCH",
        body: resource,
      }),
    }),
    destroyCustomer: builder.mutation<void, number>({
      query: (customerId) => ({
        url: `/customers/${customerId}/`,
        method: "DELETE",
      }),
    }),
  }),
})

export const {
  useListCustomersQuery,
  useCreateCustomerMutation,
  useRetrieveCustomerQuery,
  useUpdateCustomerMutation,
  usePartialUpdateCustomerMutation,
  useDestroyCustomerMutation,
} = customersApi
