import { DetailHeading } from './DetailHeading';
import { HStack, Text, VStack } from '@chakra-ui/react';
import { TicketResource } from '../pages/user/TicketDetailPage';
import { TicketDetailWrapperProps } from './TicketDetailWrapper';


interface TicketEditPageHeader extends TicketDetailWrapperProps {
    dataresource?: TicketResource
}

export function TicketEditPageHeader({
    dataresource
}: TicketEditPageHeader) {
    return (
        <HStack w={{ base: "95%", md: "90%" }} h="80px" alignItems={"center"} justifyContent={"space-between"} px={0} py={3} mt={1} mx={"auto"} >
            <VStack gap={0} alignItems={"stretch"}>
                <DetailHeading title={`#${dataresource?.number}`} fontSize={{ base: "1.5rem", sm: "1.8rem", lg: "2rem" }} lineHeight={"normal"} />
                <Text fontWeight={400} fontSize={{ base: "12px", lg: "14px" }} color={"#737791"} >Edit Ticket Details</Text>
            </VStack>
        </HStack>
    )
}
