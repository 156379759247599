import { getDateString } from "../../app/utils";
import { Box, Flex, Text } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import TimeEntryCard from "../../components/TimeEntryCard";
import { DetailHeading } from "../../components/DetailHeading";
import { KnownIcon } from "../../components/widgets/KnownIcon";
import { PaginatedListLayout } from "../../layout/areas/PaginatedListLayout";
import { FilterValues, OptionType } from "../../features/tickets/tickets";
import { useDriverTimeEntryQuery } from "../../features/timeEntries/timeEntriesApi";
import { PuffLoader } from "react-spinners";
import { ErrorBox } from "../../components/ErrorBox";
import { useConfigCheck } from "../../app/hooks";

export type DateRange = [Date | null, Date | null];

export function SubmittedTimeEntries() {

    const { userProfile: { role } = {} } = useConfigCheck()
    const initialState: FilterValues = { dateRange: [null, null] }
    const [filters, setFilters] = useState<FilterValues>(initialState);
    const [finalFilter, setFinalFilter] = useState<FilterValues>(filters)
    const [filterApplied, setFilterApplied] = useState(false)              //track satte whether filter is applied or not
    const [networkError, setNetworkError] = useState<string>("");
    const { dateRange } = finalFilter;

    const url = useMemo(() => {
        const params = new URLSearchParams();
        if (dateRange[0]) params.append('date_range_after', getDateString(dateRange[0]));
        if (dateRange[1]) params.append('date_range_before', getDateString(dateRange[1]));
        return params.toString();
    }, [finalFilter]);

    const { data, isFetching, refetch, isError, error, isSuccess } = useDriverTimeEntryQuery(url)

    const handleApplyFilter = () => {
        const hasDateRange = filters.dateRange[0] !== null;

        if (!filterApplied && hasDateRange) {
            setFilterApplied(true)
        } else {
            if (filterApplied && !hasDateRange) setFilterApplied(false)
        }
        setFinalFilter(filters)
        return hasDateRange  //return value to close popover in mobile view
    }

    //reset filter handler
    const handleResetFilter = () => {
        setFilters(initialState)
        setFinalFilter(initialState)
        setFilterApplied(false)
    }

    //page change handler
    const handlePageChange = (newPage?: number) => {
        setFilters(prev => ({ ...prev, currentPage: newPage || 1 }));
        setFinalFilter(prev => ({ ...prev, currentPage: newPage || 1 }))
    }
    //page size change handler
    const handlePageSizeChange = (size: OptionType) => {
        setFilters(prev => ({ ...prev, itemsPerPage: size }));
        setFinalFilter(prev => ({ ...prev, itemsPerPage: size }))
    }

    //refetch data on filter applied
    useEffect(() => {
        if (filterApplied) refetch()
    }, [finalFilter])

    //set error if there is network issue
    useEffect(() => {
        if (error && 'status' in error && error.status === 'FETCH_ERROR') {
            setNetworkError("You appear to have no or limited connectivity. This information will be visible once connected.");
            window.scrollTo({ top: 0, behavior: "smooth" })
        } else if (isSuccess && data) {
            setNetworkError("");
        }
    }, [isError, isSuccess, data]);
    return (
        !isError ?
            <Box w={{ base: "98%", sm: "95%", md: "90%" }} maxW={"1440px"} p={2} mx="auto">
                <PaginatedListLayout
                    isLoading={isFetching}
                    title={"My Time"}
                    filters={filters}
                    setFilters={setFilters}
                    filterApplied={filterApplied}
                    handleApplyFilter={handleApplyFilter}
                    handleResetFilter={handleResetFilter}
                    handlePageChange={handlePageChange}
                    handlePageSizeChange={handlePageSizeChange}
                >
                    {
                        !isFetching ?
                            data && data?.daily_summary.length > 0 && role !== "swamper" ?
                                <>
                                    {filterApplied &&
                                        <Flex width="full" justifyContent={'flex-end'} alignItems={"center"}>
                                            <DetailHeading title='Total Hours : ' fontSize={16} />
                                            <Flex w='fit-content' p={1} gap={'10px'} fontSize={[12, 14]} borderRadius={"40px"} bgColor={'#EBEEFF'} justifyContent={"center"} alignItems={"center"}>
                                                <KnownIcon name="clock" display={"inline"} boxSize={{ base: "16px", md: "20px", lg: "24px" }} />
                                                <Text noOfLines={1} isTruncated>{data?.grand_total_hours}</Text>
                                            </Flex>
                                        </Flex>}
                                    {data?.daily_summary?.map((item: any, index: number) => (
                                        <TimeEntryCard key={index} timeEntry={item} />
                                    ))}
                                </>
                                :
                                <Box flex={1} alignContent={"center"}>No time entries found</Box>
                            :
                            <Flex w="full" justifyContent="center" alignItems="center" flex={1}>
                                <PuffLoader
                                    color="#3D82CE"
                                    size={80}
                                />
                            </Flex>

                    }
                </PaginatedListLayout>
            </Box>
            :
            <ErrorBox show={isError} error={error} message={networkError} />
    )
}
