import { DetailTile } from "../../components/DetailTile";
import { PanelWrapper } from "../../components/PanelWrapper";
import { ProductResource } from "../products/productsSlice";
import { WrapperProps } from "../../components/TicketDetailWrapper";
import { TicketResource } from "../../pages/user/TicketDetailPage";
import { Box, HStack, Stack, StackDivider, useMediaQuery, VStack } from "@chakra-ui/react";

interface LabelInfoPanelProps extends WrapperProps {
    dataresource?: TicketResource
}

export function LabelInfoPanel({
    dataresource: ticket
}: LabelInfoPanelProps) {
    if (!ticket) return null;

    const [isBreakPoint] = useMediaQuery("(max-width: 700px)")
    return (
        <Box w={{ base: "98%", sm: "95%", md: "90%" }} maxW={"1440px"} p={2} mx="auto">
            <PanelWrapper title="Label Information">
                <VStack align="stretch" gap={{ base: 2, lg: 4 }} p={2} divider={<StackDivider />}>
                    {ticket?.products?.length !== 0 ?
                        ticket?.products.map((product: ProductResource, index: number) => (
                            <VStack key={index} w="full" p={2} align="stretch" gap={{ base: 3, lg: 5 }} divider={<StackDivider />}>
                                <HStack w={"full"} alignItems="flex-start" gap={{ base: 3, lg: 5 }} divider={<StackDivider />}>
                                    <DetailTile flexBasis="full" title="Shipping Name" icon="shippingBox" detail={product?.name} />
                                </HStack>
                                <Stack direction={isBreakPoint ? "column" : "row"} w={"full"} alignItems="flex-start" gap={{ base: 3, lg: 5 }} divider={<StackDivider />}>
                                    <Stack direction="row" w="full" gap={5} divider={<StackDivider />}>
                                        <DetailTile flexBasis={'50%'} title="Qty" icon="activity" detail={ticket?.productdetails?.[index]?.quantity} />
                                        <DetailTile flexBasis={'50%'} title="Pkg" icon="package" detail={ticket?.productdetails?.[index]?.packages} />
                                    </Stack>
                                    <Stack direction="row" w="full" gap={5} divider={<StackDivider />}>
                                        <DetailTile flexBasis={'50%'} title="Toxic" icon="toxic" detail={ticket?.productdetails?.[index]?.toxic_by_inhalation ? "Yes" : "No"} />
                                        <DetailTile flexBasis={'50%'} title="Subsidiary Class" icon="subsidiaryClass" detail={ticket?.productdetails?.[index]?.subsidiary_class} />
                                    </Stack>
                                </Stack>
                            </VStack>
                        )
                        ) :
                        <Box w="full" fontSize="14px" color="#455360" bgColor={"#fff"} borderRadius={"8px"} >
                            No additional products have been added
                        </Box>
                    }
                </VStack>
            </PanelWrapper>
        </Box>
    )
}

