import { Button, ButtonProps, Link, useBreakpointValue } from "@chakra-ui/react";

export interface CustomButtonProps extends ButtonProps {
    title: string;
    type?: "button" | "submit" | "reset";
    variant?: "solid" | "outline" | 'link';
    colorScheme?: "blue" | "grey" | "red";
    href?: string;
    target?: "_self" | "_blank"
}

export function CustomButton({
    title,
    type = "button",
    variant = "solid",
    colorScheme = "blue",
    href,
    target = "_blank",
    ...rest
}: CustomButtonProps) {
    const solidStyles = {
        blue: {
            bg: "#3455FF",
            color: "#fff",
            _hover: { bg: "#1A3AFF" },
        },
        grey: {
            bg: "#EDF2F7",
            color: "#455360",
            _hover: { bg: "#9BADC1" },
        },
        red: {
            bg: "#E02020",
            color: "#fff",
            _hover: { bg: "#A0AEC0" },
        }
    };

    const outlineStyles = {
        blue: {
            bg: "#fff",
            color: "#3455FF",
            border: "1px solid #3455FF",
            _hover: { bg: "#3455FF", color: "#fff" },
        },
        grey: {
            bg: "#fff",
            color: "#EDF2F7",
            border: "1px solid #EDF2F7",
            _hover: { bg: "#EDF2F7", color: "#fff" },
        },
        red: {
            bg: "#fff",
            color: "#E02020",
            border: "1px solid #E02020",
            _hover: { bg: "#E02020", color: "#fff" },
        }
    };
    const styles = variant === "solid" ? solidStyles[colorScheme] : outlineStyles[colorScheme];

    return href ? (
        <Link href={href} target={target}>
            <Button type={type} fontWeight={500} borderRadius="4px" size={["sm"]} fontSize={12} w={[90, 120]} {...styles} {...rest}>
                {title}
            </Button>
        </Link>
    ) : (
        <Button type={type} fontWeight={500} borderRadius="4px" size={["sm"]} fontSize={12} w={[90, 120]} {...styles} {...rest}>
            {title}
        </Button>
    );
}
