import { Flex } from "@chakra-ui/react";
import { PuffLoader } from "react-spinners";
import { useEffect, useState } from "react";
import { useScrollTo } from "../../app/hooks";
import { Params, useParams } from "react-router-dom";
import { ErrorBox } from "../../components/ErrorBox";
import { Container } from "../../components/Container";
import { BasicPanel } from "../../features/tickets/BasicPanel";
import { PickUpPanel } from "../../features/tickets/PickUpPanel";
import { MainPageHeader } from "../../components/MainPageHeader";
import { BreadcrumbItemType } from "../../components/BreadCrumbs";
import { ProductsPanel } from "../../features/draft/ProductsPanel";
import { DropOffPanel } from "../../features/tickets/DropOffPanel";
import { TicketDetailWrapper } from "../../components/TicketDetailWrapper";
import { useRetrieveTicketQuery, } from "../../features/tickets/ticketsApi";
import { TicketEditPageHeader } from "../../components/TicketEditPageHeader";
import { useListTerminalsQuery } from "../../features/terminals/terminalsApi";
import { AdditionalItemsPanel } from "../../features/draft/AdditionalItemsPanel";
import { DraftLocationsPanel } from "../../features/locations/DraftLocationsPanel";
import { AttachmentDropZone } from "../../components/forms/fields/AttachmentDropZone";

export function TicketEditPage() {
    const { id: ticketId } = useParams<Params>();
    const [setRef, scrollTo] = useScrollTo();
    const [networkError, setNetworkError] = useState<string>('');
    useListTerminalsQuery();

    // Retrive ticket information
    const { isLoading, isError, error, isSuccess, data: ticket } = useRetrieveTicketQuery(
        Number(ticketId),
    );

    useEffect(() => {
        if (error && 'status' in error && error.status === 'FETCH_ERROR') {
            setNetworkError("You appear to have no or limited connectivity. This information will be visible once connected.");
            window.scrollTo({ top: 0, behavior: "smooth" })
        } else if (isSuccess && ticket) {
            // Clear the error if data fetch is successful
            setNetworkError("");
        }
    }, [isError, isSuccess, ticket]);

    const breadCrumbItems: BreadcrumbItemType[] = [
        { label: 'Home', path: '/user/home', icon: 'home' },
        { label: 'View All Tickets', path: '/user/tickets' },
        { label: `#${ticket?.number || ''}`, path: `/user/tickets/${ticket?.id}` },
        { label: `Edit Ticket`, isCurrentPage: true },
    ];
    return (
        <>
            <MainPageHeader isLoading={isLoading} breadCrumbItems={breadCrumbItems} />

            {isLoading || !ticket ? (
                <Flex w="full" justifyContent="center" alignItems="center" flex={1}>
                    <PuffLoader color="#3D82CE" size={80} />
                </Flex>
            ) : isError ? (
                <Container>
                    <ErrorBox show={isError} error={error} message={networkError} />
                </Container>
            ) : (
                <TicketDetailWrapper>
                    <TicketEditPageHeader />
                    <BasicPanel scrollTo={scrollTo} />
                    <DraftLocationsPanel scrollTo={scrollTo} ref={setRef('LocationsForm')} />
                    <ProductsPanel ref={setRef('productForm')} />
                    <PickUpPanel ref={setRef('pickUpForm')} scrollTo={scrollTo} />
                    <DropOffPanel ref={setRef('dropOffForm')} scrollTo={scrollTo} />
                    <AdditionalItemsPanel />
                    <AttachmentDropZone />
                </TicketDetailWrapper>
            )}
        </>

    )
}
