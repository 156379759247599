import { useEffect, useRef } from "react";
import { bolmintApi } from "../../app/api";
import { useNavigate } from "react-router-dom";
import { logoutUser, selectIsLoggedIn } from "./sessionSlice";
import { VStack, Heading, Box, Text } from "@chakra-ui/react";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { CustomButton } from "../../components/forms/CustomButton";

export function SessionLogout() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const isLoggedIn = useAppSelector(selectIsLoggedIn)
  const isTriggered = useRef(false)
  useEffect(() => {
    if (!isTriggered.current) {
      isTriggered.current = true
      dispatch(bolmintApi.util.resetApiState());
      dispatch(logoutUser())
    }
  }, [dispatch])

  const message = isLoggedIn ? "Logging out..." : "You logged out!"
  const handleLogin = () => navigate("auth/login")

  return (
    <Box w="full" p={5} mt={5}>
      <VStack width="full" gap={6} minH={"200px"}>
        <Heading as="h5" size="md">
          Logout
        </Heading>
        <Box w="full">
          <Text align="center">{message}</Text>
        </Box>
        <CustomButton type="submit" title="Log In" w={120} onClick={handleLogin} />
      </VStack>
    </Box>
  )
}
