import { formatDateOnly } from '../../app/utils';
import { useLocation, useParams } from 'react-router-dom';
import { KnownIcon } from '../../components/widgets/KnownIcon';
import { useAppSelector, useConfigCheck } from '../../app/hooks';
import { Draft, selectDraft } from '../../features/draft/draftSlice';
import { EquipmentTrait } from '../../features/equipments/EquipmentTrait';
import { selectCustomerById } from '../../features/customers/customersSlice';
import { forwardRef, Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { selectTerminalById, selectLocationById } from '../../features/terminals/terminalsSlice';
import { ProductResource, selectAllProducts, selectProductById } from '../../features/products/productsSlice';
import { Box, Button, Divider, Flex, HStack, Image, SimpleGrid, Spacer, StackDivider, StackProps, Table, Tbody, Td, Text, Th, Thead, Tr, VStack } from '@chakra-ui/react';

interface PrintFieldProps extends StackProps {
    label?: string
    children: any
}

interface PrintContentProps {
    draft: Draft
    isWithCharge?: boolean
    onLogoLoad?: () => void
}



function PrintField({ label, children, ...rest }: PrintFieldProps) {
    return (
        <VStack fontSize={"10px"} alignItems={"stretch"} gap={0} {...rest}>
            <Text fontWeight={400} color={"#666E82"}  >
                {label && `${label}`}
            </Text>
            <Text color={"#1A202C"} fontWeight={600}>{children || "--"}</Text>
        </VStack>
    )
}

function PrintHeader({ draft, onLogoLoad }: PrintContentProps) {
    const { compConfig } = useConfigCheck()
    const formatedAddress = useMemo(() => {
        if (compConfig) return compConfig?.company_address.replace(/\r\n/g, "<br />")
    }, [compConfig])


    return (
        <HStack color={"#1A202C"} className="bgColor" w="full" p={5} gap={[2, 3]} borderRadius={"6px"} justifyContent="space-between" bgColor={"#F8F8FB"} >
            <Image id="logo" src={compConfig?.large_logo} w={"110px"} onLoad={onLogoLoad} />
            <Text
                align="center"
                fontSize={"14px"}
                fontWeight={700}
            >
                {compConfig?.name} <br /> BILL OF LADING <br />
                <Text as={"span"}> #{draft?.number || "--"} </Text>
            </Text>
            <VStack fontSize={"10px"} textAlign="right" alignItems={"end"}>
                <div
                    dangerouslySetInnerHTML={{
                        __html: formatedAddress,
                    }}
                />
                <Text fontWeight={700} fontSize={"12px"}>{compConfig?.tax_number}</Text>
            </VStack>
        </HStack>
    )
}

function PrintFooter({ draft }: PrintContentProps) {

    const { basic } = draft

    // Memoized contact list and consignor contact details
    const contactList = useAppSelector((state) =>
        selectCustomerById(state, basic?.customer || 0)?.contacts || []
    );
    const contactDetails = useMemo(
        () => contactList.find((item) => item.id === basic?.consignor_contact),
        [contactList, basic?.consignor_contact]
    );


    return (
        <Box w="full" px={5} mt={2}>
            <VStack w="full" bgColor={"#F8F8FB"} className="bgColor avoidSplit" borderRadius={"4px"} p={5} fontSize={10} divider={<StackDivider />}>
                <Text color={"#666E82"}>
                    Consignor’s Certification: I hereby declare that the contents of this
                    consignment are fully and accurately described above by the proper
                    shipping name, are properly classified and packaged, have dangerous
                    goods safely marks properly affixed or displayed on them, and are in all
                    respects in proper condition for transport according to the
                    Transportation of Dangerous Goods Regulations.
                </Text>
                <HStack w="full" justifyContent="space-between" p={2} alignItems={"flex-start"} >
                    <VStack alignItems={'stretch'} gap={1}>
                        <Text textTransform="uppercase" color={"#666E82"}>Client Name</Text>
                        <Text color={"#1A202C"} fontWeight={600}>
  {contactDetails?.first_name && contactDetails?.last_name 
    ? `${contactDetails.first_name} ${contactDetails.last_name}` 
    : '--'}
</Text>
                    </VStack>
                    <VStack alignItems={'stretch'} gap={1}>
                        <Text textTransform="uppercase" color={"#666E82"}>Client Approved</Text>
                    </VStack >
                    <VStack alignItems={'stretch'} gap={1}>
                        <Text textTransform="uppercase" color={"#666E82"}>Telephone</Text>
                        <Text color={"#1A202C"} fontWeight={600}>{contactDetails?.phone || '--'}</Text>
                    </VStack >
                </HStack >
            </VStack >
        </Box>
    )
}


function StampField({ label, children }: PrintFieldProps) {
    return (
        <HStack fontSize={"10px"} alignItems={"stretch"} gap={2}>
            <Text fontWeight={400} color={"#666E82"}  >
                {label && `${label}:`}
            </Text>
            <Text color={"#1A202C"} fontWeight={600} display={'block'} minH={"12px"}>{children}</Text>
        </HStack>
    )
}

function PrintOverview({ draft, isWithCharge }: PrintContentProps) {
    const { basic, locations, pickUp, stampDetails, droppOff } = draft
    const { userProfile: { phone, user } = {} } = useConfigCheck() //get role of current user
    const getTerminalName = (terminal: string | number | undefined) => {
        if (typeof terminal === "number") {
            return useAppSelector(state => selectTerminalById(state, terminal)?.name);
        }
        return terminal;
    };
    const getLocationName = (terminal: string | number | undefined, location: number | string | undefined) => {
        if (typeof terminal === "string" || typeof location === "string") {
            return location;
        }
        return location && useAppSelector(state => selectLocationById(state, terminal || 0, location)?.name);
    };
    const consignorTerminal = getTerminalName(locations?.consignor?.terminal);
    const consignorLocation = getLocationName(locations?.consignor?.terminal, locations?.consignor?.location);
    const consigneeTerminal = getTerminalName(locations?.consignee?.terminal);
    const consigneeLocation = getLocationName(locations?.consignee?.terminal, locations?.consignee?.location);
    const customer = useAppSelector(
        (state) => selectCustomerById(state, basic?.customer || 0)?.name,
    )
    const residue_last_contained = typeof pickUp?.residue_last_contained == "number" ? useAppSelector((state) => selectProductById(state, pickUp?.residue_last_contained)?.name) : pickUp?.residue_last_contained


    // Memoized contact list and consignor contact details
    const contactList = useAppSelector((state) =>
        selectCustomerById(state, basic?.customer || 0)?.contacts || []
    );
    const billingDetail = useMemo(
        () => contactList.find((item) => item.id === basic?.billing_email),
        [contactList, basic?.billing_email]
    );

    return (
        <HStack w="full" justifyContent={"space-between"} align="start" gap={1} fontSize={[13, 14]} px={5} mt={2}>
            <VStack flex={1} maxW={"60%"} align="start" h="full" spacing={2} gap={2}>
                <SimpleGrid w="full" columns={2} spacing={1} >
                    <Text flex={1} fontWeight={700} fontSize={16} whiteSpace="normal" wordBreak={"break-word"} textOverflow="ellipsis" overflow="hidden" noOfLines={2}  >{customer || "--"}</Text>
                    <PrintField label="Date">
                        {formatDateOnly(basic?.reference_date)}
                    </PrintField>
                </SimpleGrid>
                <SimpleGrid w="full" columns={2} spacing={1} >
                    <PrintField label="Driver Name">
                        {phone ? `${user?.first_name}${' '}${user?.last_name}${' '}(${phone})` : `${user?.first_name}${' '}${user?.last_name}`}
                    </PrintField>
                    <PrintField label="Billing Email Address">
                        {billingDetail?.email}
                    </PrintField>
                </SimpleGrid>
                <SimpleGrid w="full" columns={2} spacing={1} >
                    <PrintField label="Equipment Used">
                        {
                            basic?.equipments && basic.equipments.length > 0 ?
                                basic?.equipments.map((equipment, index) => (
                                    <Fragment key={index}>
                                        <EquipmentTrait id={equipment} />
                                        {index !== (basic?.equipments?.length ?? 0) - 1 && ", "}
                                    </Fragment>
                                )) :
                                "--"
                        }
                    </PrintField>
                    <PrintField label="Emergency Contact #">
                        {basic?.emergency_contact || '--'}
                    </PrintField>
                </SimpleGrid>
                <SimpleGrid w="full" columns={2} spacing={1} >
                    <PrintField label="Confirmed Empty">
                        {pickUp?.confirmed_empty ? "Yes" : "No"}
                    </PrintField>
                    <PrintField label="Last Contained">
                        {residue_last_contained}
                    </PrintField>
                </SimpleGrid>
                <SimpleGrid w="full" columns={2} spacing={1} >
                    <PrintField label="SW Percent">
                        {droppOff?.sw_percent}
                    </PrintField>
                    <PrintField label="Sequence">
                        {droppOff?.sequence}
                    </PrintField>
                </SimpleGrid>
                <HStack w="90%" alignItems={"flex-start"}>
                    <Box maxW={"50%"} >
                        <Text color={"#666E82"} fontSize={"10px"}>From</Text>
                        <Flex gap={{ base: "6px", lg: 3 }} alignItems={"flex-start"}>
                            <KnownIcon name="location" boxSize={{ base: "16px", md: "20px", lg: "24px" }} />
                            {
                                (consignorTerminal || locations?.consignor?.lsd || consignorLocation) ?
                                    <Box fontSize={"10px"} maxW={"80%"}>
                                        <Text color={"#0E1628"} fontWeight={600}>{consignorTerminal || "--"} </Text>
                                        <Text color={"#666E82"}>{locations?.consignor?.lsd || "--"} </Text>
                                        <Text color={"#666E82"} noOfLines={3}>{consignorLocation || "--"} </Text>
                                    </Box>
                                    : "--"
                            }
                        </Flex>
                    </Box>
                    <Spacer my="auto" h={0} borderBottom={"1px dashed #9F9F9F"} />
                    <Box maxW={"50%"} >
                        <Text color={"#666E82"} fontSize={"10px"}>To</Text>
                        <Flex gap={{ base: "6px", lg: 3 }} alignItems={"flex-start"}>
                            <KnownIcon name="location" boxSize={{ base: "16px", md: "20px", lg: "24px" }} />
                            {
                                (consigneeTerminal || locations?.consignee?.lsd || consigneeLocation) ?
                                    <Box fontSize={"10px"} maxW={"80%"}>
                                        <Text color={"#0E1628"} fontWeight={600} >{consigneeTerminal || "--"} </Text>
                                        <Text color={"#666E82"} >{locations?.consignee?.lsd || "--"} </Text>
                                        <Text color={"#666E82"} noOfLines={3} >{consigneeLocation || "--"} </Text>
                                    </Box>
                                    : "--"
                            }
                        </Flex>
                    </Box>
                </HStack>
            </VStack>
            {
                <VStack w="280px" h="265px" borderWidth={1} borderRadius={"6px"} borderColor="#666E82" p={2} fontWeight={500} justifyContent="space-between">
                    <VStack w="full" alignItems={"stretch"} gap={4}>
                        <Text align="center" fontSize={"10px"} fontWeight={600}>
                            AFE STAMP
                        </Text>
                        <VStack align="start" gap={3}>
                            <StampField label="AEF# / Cost Class"> {stampDetails?.cost_class} </StampField>
                            <StampField label="Minor"> {stampDetails?.minor_class} </StampField>
                            <StampField label="Major"> {stampDetails?.major_class} </StampField>
                            <StampField label="Other"> {stampDetails?.other} </StampField>
                        </VStack>
                    </VStack>
                    <Box w="full">
                        {
                            isWithCharge && stampDetails?.digital_sign &&
                            < Image
                                w="full"
                                h="70px"
                                src={stampDetails?.digital_sign || ""}
                                objectFit="contain"
                            />
                        }
                        <Divider borderColor="#666E82" />
                        <Text fontSize={"10px"} color={"#1A202C"} fontWeight={600}>
                            Signature
                        </Text>
                    </Box>
                </VStack>
            }
        </HStack >
    )
}

function PrintTankGaugeReadings({ draft }: PrintContentProps) {

    return (
        <>
            <VStack w="full" gap={0} px={5} >
                <Text fontWeight={700} color={"#1A202C"} fontSize={"12px"} w={"full"} textAlign={"center"} my={2}>TANK GUAGE READINGS</Text>
                <Box w="full" border={'1px'} borderRadius='4px' borderColor={"#EDF2F7"}>
                    <Table fontSize={12} color={"#4A5568"} >
                        <Thead bgColor={"#F8F8FB"} className="bgColor">
                            <Tr sx={{ th: { textAlign: "center", py: '8px', lineHeight: '14px', w: "33.33%" } }}>
                                <Th>TANK</Th>
                                <Th>START</Th>
                                <Th>FINISH</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {draft?.pickUp?.tank_gauge_details &&
                                draft?.pickUp?.tank_gauge_details?.length !== 0 ?
                                draft?.pickUp?.tank_gauge_details.map((record, index) => {
                                    return (
                                        <Tr key={index} sx={{ td: { textAlign: 'center', p: 1, w: "33.33%" }, _last: { td: { borderBottom: 'none' } } }}>
                                            <Td>{++index}</Td>
                                            <Td>{record?.start || '--'}</Td>
                                            <Td>{record?.finish || '--'}</Td>
                                        </Tr>
                                    )
                                })
                                :
                                <Tr>
                                    <Td p={1} colSpan={3} textAlign="center">No tank gauge readings.</Td>
                                </Tr>
                            }
                        </Tbody>
                    </Table>
                </Box>
            </VStack >
        </>
    )
}

function PrintProducts({ draft }: PrintContentProps) {
    const products = useAppSelector(selectAllProducts)
    const productsById = useMemo(() => {
        const productsObj: { [key: number]: ProductResource } = {}
        products.forEach((item) => {
            productsObj[item.id] = item
        })
        return productsObj
    }, [draft?.products])

    return (
        <VStack w="full" gap={0} px={5} >
            <Text fontWeight={700} color={"#1A202C"} fontSize={"12px"} w={"full"} textAlign={"center"} my={2}>SHIPMENT</Text>
            <Box w="full" border={'1px'} borderRadius='4px' borderColor={"#EDF2F7"}>
                <Table fontSize={12} color={"#4A5568"} >
                    <Thead bgColor={"#F8F8FB"} verticalAlign={'top'} className="bgColor">
                        <Tr className="avoidSplit" sx={{ th: { textAlign: 'center', p: 2, lineHeight: '14px' } }} >
                            <Th>UN Number</Th>
                            <Th w={[0, 300]}>Shipping Name</Th>
                            <Th>Primary Class</Th>
                            <Th>Subsidiary Class</Th>
                            <Th>Packing Group</Th>
                            <Th>Toxic by Inhalation</Th>
                            <Th>Total Quantity</Th>
                            <Th># of Packages</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {draft?.products && draft.products.length > 0 ? (
                            draft.products.map((detail, index) => {
                                const product = typeof detail?.product === "number" ? productsById[Number(detail.product)] : detail?.product;
                                const isProductValid = product && typeof product !== 'string';

                                return (
                                    <Tr key={index} className="avoidSplit" sx={{ td: { textAlign: "center" }, _last: { td: { borderBottom: 'none' } } }}>
                                        <Td p={1} textAlign="center">{isProductValid && product.un_number ? product.un_number : "--"}</Td>
                                        <Td p={1} textAlign="center">{isProductValid && product.name ? product.name : "--"}</Td>
                                        <Td p={1} textAlign="center">{isProductValid && product.primary_class ? product.primary_class : "--"}</Td>
                                        <Td p={1} textAlign="center">{detail?.subsidiary_class || "--"}</Td>
                                        <Td p={1} textAlign="center">{isProductValid && product.packing_group ? product.packing_group : "--"}</Td>
                                        <Td p={1} textAlign="center">{detail?.toxic_by_inhalation ? "Yes" : 'No'}</Td>
                                        <Td p={1} textAlign="center">{detail?.quantity || '--'}</Td>
                                        <Td p={1} textAlign="center">{detail?.packages || '--'}</Td>
                                    </Tr>
                                )
                            })) :
                            (
                                <Tr>
                                    <Td p={1} colSpan={8} textAlign="center">No products recorded.</Td>
                                </Tr>
                            )
                        }
                    </Tbody>
                </Table>
            </Box>
        </VStack >
    )
}

function PrintCharges({ draft }: PrintContentProps) {
    return (
        <VStack w="full" textAlign={"center"} gap={0} px={5}  >
            <Text fontWeight={700} color={"#1A202C"} fontSize={"12px"} w={"full"} textAlign={"center"} my={2}>BILLING</Text>
            <Box w="full" border={'1px'} borderRadius='4px' borderColor={"#EDF2F7"}>
                <Table fontSize={12} color="#4A5568">
                    <Thead bgColor={"#F8F8FB"} verticalAlign={'top'} className="bgColor">
                        <Tr sx={{ th: { textAlign: "center", py: '8px', lineHeight: '14px' }, "th:first-of-type": { textAlign: "left" } }}>
                            <Th p={1} w={200} >Description</Th>
                            <Th p={1} >Quantity</Th>
                            <Th p={1} >Unit</Th>
                            <Th p={1} >Rate</Th>
                            <Th p={1} >Amount</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {
                            draft?.charges && draft?.charges.length > 0 ? (
                                draft?.charges?.map((charge, index) => {
                                    return (
                                        <Tr key={index} sx={{ td: { textAlign: "center" }, "td:first-of-type": { textAlign: "left" }, _last: { td: { borderBottom: 'none' } } }} >
                                            <Td p={1} >{charge.description || '--'}</Td>
                                            <Td p={1} >{charge.quantity || '--'}</Td>
                                            <Td p={1} >{charge.unit || '--'}</Td>
                                            <Td p={1} >{charge.rate || '--'}</Td>
                                            <Td p={1} >{charge.amount || '--'}</Td>
                                        </Tr>
                                    )
                                })
                            ) : (
                                <Tr>
                                    <Td h="29px" p={0} colSpan={5} borderBottom='none'></Td>
                                </Tr>
                            )
                        }
                    </Tbody>
                </Table>
            </Box>
        </VStack >
    )
}

function PrintDescription({ draft, isWithCharge }: PrintContentProps) {

    const { charges, droppOff, services } = draft || {}

    const totalAmount = useMemo(() =>
        charges?.reduce((total, { amount }) => total + Number(amount), 0)
        , [draft?.charges]);

    const description = useMemo(() => {
        const desc: string[] = [];
        if (droppOff?.work_description) desc.push(droppOff.work_description);
        services?.forEach(({ description }) => {
            if (description) desc.push(description);
        });
        return desc.join('. ');
    }, [draft]);

    return (
        <Box w="full" px={5} mt={2}>
            <HStack w="full" bgColor={"#F8F8FB"} className="bgColor avoidSplit" h="fit-content" borderRadius={"4px"} p={2} justifyContent="space-between" fontSize={12}>
                <VStack alignItems={"stretch"} p={2} gap={2} maxW={"80%"}>
                    <Text fontWeight={600} color={"#666E82"}>DESCRIPTION OF WORK</Text>
                    <Text whiteSpace="normal" color={"#1A202C"} wordBreak="break-word">{description || '--'}</Text>
                </VStack>
                {
                    isWithCharge && (
                        <VStack alignItems={"stretch"} textAlign={"right"} p={2} gap={2} >
                            <Text fontWeight={600} color={"#666E82"}>TOTAL CHARGES</Text>
                            <Text color={"#1A202C"} > $ {(totalAmount || 0).toFixed(2)}</Text>
                        </VStack>
                    )
                }
            </HStack>
        </Box>
    )
}

export const PrintContent = forwardRef<HTMLDivElement, PrintContentProps>(
    ({ draft, isWithCharge, onLogoLoad }: PrintContentProps, ref) => {
        return (
            <VStack w="full" gap={0} padding={0} >
                <PrintHeader draft={draft} onLogoLoad={onLogoLoad} />
                <PrintOverview draft={draft} isWithCharge={isWithCharge} />
                <PrintTankGaugeReadings draft={draft} />
                <PrintProducts draft={draft} />
                {isWithCharge && <PrintCharges draft={draft} />}
                {<PrintDescription draft={draft} isWithCharge={isWithCharge} />}
                {<PrintFooter draft={draft} />}
            </VStack>
        )
    },
)

export function PrintDraft() {
    const { id } = useParams()
    const draftIndex: number = id !== undefined ? parseInt(id) - 1 : 0
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const { compConfig } = useConfigCheck();  //get company config
    const currentDraft = useAppSelector(selectDraft(draftIndex))  // get current draft
    const withCharge = searchParams.get('withCharge') === "true";

    const [isLogoLoaded, setIsLogoLoaded] = useState(false);
    // Callback to trigger when logo loads
    const handleLogoLoad = () => setIsLogoLoaded(true);

    const hasPrinted = useRef(false);
    useEffect(() => {
        if (currentDraft && !hasPrinted.current && compConfig && isLogoLoaded) {
            const printTimeout = setTimeout(() => {
                document.getElementById("printBtn")?.click();
                hasPrinted.current = true
            }, 500)
            return () => clearTimeout(printTimeout);
        }
    }, [currentDraft, compConfig, isLogoLoaded])
    return (
        <>
            <Button display={"none"} id="printBtn" onClick={() => window.print()} />
            <PrintContent draft={currentDraft} isWithCharge={withCharge} onLogoLoad={handleLogoLoad} />
        </>
    )
}

