import { Text, TextProps } from '@chakra-ui/react'


interface DetailHeadingprops extends TextProps {
    title: string
}

export function DetailHeading({
    title,
    fontSize = { base: "16px", sm: "18px", md: "20px" },
    fontWeight = 600,
    color = "#05004E",
    whiteSpace = "normal",
    wordBreak = "break-word",
    ...rest
}: DetailHeadingprops) {
    return (
        <Text
            fontSize={fontSize}
            color={color}
            whiteSpace={whiteSpace}
            fontWeight={fontWeight}
            noOfLines={2}
            {...rest}
        >
            {title}
        </Text>
    )
}
