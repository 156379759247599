import { useState } from "react";
import { ChargesForm } from "../charges/ChargesForm";
import { Container } from "../../components/Container";
import { PanelWrapper } from "../../components/PanelWrapper";
import { ModalForm } from "../../components/forms/ModalForm";
import { selectTicketCharges } from "../tickets/ticketsSlice";
import { Draft, selectDraftCharges } from "../draft/draftSlice";
import { useAppSelector, useConfigCheck } from "../../app/hooks";
import { CustomButton } from "../../components/forms/CustomButton";
import { TicketResource } from "../../pages/user/TicketDetailPage";
import { ChargeWrapperProps } from "../../components/ChargeDetailWrapper";
import { Box, ButtonGroup, HStack, Switch, Table, Tbody, Td, Text, Th, Thead, Tr, useDisclosure, useMediaQuery } from "@chakra-ui/react";

export interface ChargePanelOverviewProps extends ChargeWrapperProps {
    dataresource?: TicketResource | Draft | null
}

export function ChargePanelOverview({ isdraft, resourceId, dataresource, handlesavecharge, handleRemoveCharge, handleAskToDriverLead }: ChargePanelOverviewProps) {
    const { isOnline } = useConfigCheck();
    const [isSmallScreen] = useMediaQuery("(max-width: 468px)");
    const [isLargerThanMobile] = useMediaQuery("(min-width: 768px)");
    const modalSize = isLargerThanMobile ? "lg" : "full";
    const { isOpen: isModalOpen, onOpen: openModal, onClose: closeModal, } = useDisclosure();

    const [isSurCharge, setSurcharge] = useState<boolean>(false);
    const [defaultValues, setDefaultValues] = useState(null); //set default value for modal

    // save handler 
    const handleAddButton = () => {
        setSurcharge && setSurcharge(false)
        setDefaultValues(null)
        openModal()
    };

    //select resource based on draft or ticket
    const selectResource = isdraft ?
        useAppSelector(selectDraftCharges(Number(resourceId))) :
        useAppSelector(selectTicketCharges(Number(resourceId)));


    return (
        <Container direction="column" condensed={!isSmallScreen} mb={10}>
            <PanelWrapper title="Charges">
                <CustomButton title="Add New Charge" onClick={handleAddButton} isDisabled={(isdraft || isOnline) ? false : true} p={4} w={[100, 120]} />
                {
                    selectResource && selectResource?.length > 0 ?
                        <Box
                            width="100%"
                            overflowX="auto"
                            className="custom-scrollbar"
                            maxWidth="calc(100vw - 90px)"
                        >
                            <Table>
                                <Thead color={"#455360"}>
                                    <Tr sx={{ th: { borderBottom: "none", fontSize: { base: "12px", md: "1rem" }, fontWeight: 600, p: 2 } }}>
                                        <Th>Name</Th>
                                        <Th>Quantity</Th>
                                        <Th>Unit</Th>
                                        <Th>Rate</Th>
                                        <Th>Amount</Th>
                                        <Th w={"100px"}> </Th>
                                    </Tr>
                                </Thead>
                                <Tbody color={"#7B8389"}>
                                    {selectResource?.map((item, index) => (
                                        <Tr key={index} h="50px" sx={{ td: { fontSize: { base: "12px", md: "1rem" }, p: 2 } }} _last={{ td: { borderBottom: "none" } }} >
                                            <Td minW={"200px"}>{item?.description}</Td>
                                            <Td>{item?.quantity}</Td>
                                            <Td>{item?.unit}</Td>
                                            <Td>{item?.rate}</Td>
                                            <Td>{item?.amount}</Td>
                                            <Td w={"100px"} textAlign="right">
                                                <ButtonGroup gap={2} verticalAlign="center">
                                                    <CustomButton title="-" w={"40px"}
                                                        isDisabled={(isOnline || isdraft) ? false : true}
                                                        onClick={() => handleRemoveCharge && handleRemoveCharge(index)} />

                                                    {
                                                        item.allow_surcharge === true &&
                                                        <CustomButton title="%" w={"40px"}
                                                            isDisabled={(isOnline || isdraft) ? false : true}
                                                            onClick={() => {
                                                                openModal()
                                                                const data = {
                                                                    description: `Fuel Surcharge (${item.description})`,
                                                                    unit: "%",
                                                                    rate: item?.amount,
                                                                    allow_surcharge: false,
                                                                }
                                                                setSurcharge && setSurcharge(true)
                                                                setDefaultValues(data as any)
                                                            }}
                                                        />
                                                    }
                                                </ButtonGroup>
                                            </Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </Box>
                        :
                        <Box w="full" fontSize="14px" color="#455360" bgColor={"#fff"} borderRadius={"8px"} p={2}>
                            No charges have been added
                        </Box>
                }

                {/* Dialog for adding new charge entry */}
                <ModalForm
                    isdraft={isdraft}
                    title="Add New Charge"
                    isOpen={isModalOpen}
                    size={modalSize}
                    onClose={closeModal}
                    onCancel={closeModal}
                    onSave={handlesavecharge}
                    defaultValues={defaultValues}
                >
                    <ChargesForm isSurCharge={isSurCharge} />
                </ModalForm>
            </PanelWrapper>
            <HStack gap={4} mt={4}>
                <Text fontWeight={600} color={"#455360"} fontSize={{ base: "12px", md: "1rem" }} >Ask Driver Lead to add Charges</Text>
                <Switch onChange={handleAskToDriverLead} isChecked={dataresource?.driver_lead_add_charges} />
            </HStack>
        </Container >

    )
}
