import { Flex } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { PuffLoader } from "react-spinners";
import { ErrorBox } from "../../components/ErrorBox";
import { useLocation, useParams } from "react-router-dom";
import { VolumeTable } from "../../features/tickets/VolumeTable";
import { ChargePageHeader } from "../../components/ChargePageHeader";
import { useRetrieveTicketQuery } from "../../features/tickets/ticketsApi";
import { ChargeDetailWrapper } from "../../components/ChargeDetailWrapper";
import { ChargePanelOverview } from "../../features/charges/ChargePanelOverview";
import { TicketServicesOverview } from "../../features/tickets/TicketServicesOverview";
import { TimeEntriesAggregated } from "../../features/timeEntries/TimeEntriesAggregated";

export function ChargesPage() {
    const { id } = useParams<{ id: string }>();
    const [networkError, setNetworkError] = useState<string>("");

    //Check whether the current page is a draft page or not.
    const pathname = useLocation().pathname;
    const isdraft = pathname.includes("user/draft");

    //retrive ticket , skip api call for draft page
    const { isLoading, isError, error, isSuccess, data, isFetching } = useRetrieveTicketQuery(Number(id), { skip: isdraft });

    useEffect(() => {
        if (error && 'status' in error && error.status === 'FETCH_ERROR') {
            setNetworkError("You appear to have no or limited connectivity. This information will be visible once connected.");
            window.scrollTo({ top: 0, behavior: "smooth" });
        } else if (isSuccess && data) {
            setNetworkError("");
        }
    }, [isError, isSuccess, data]);

    return (
        <>
            {isLoading ? (
                <Flex w="full" justifyContent="center" alignItems="center" flex={1}>
                    <PuffLoader color="#3D82CE" size={80} />
                </Flex>
            ) : isError ? (
                <ErrorBox show={isError} error={error} message={networkError} />
            ) : (
                <ChargeDetailWrapper isLoading={isFetching} isError={isError}>
                    <ChargePageHeader />
                    <VolumeTable />
                    <TimeEntriesAggregated />
                    <TicketServicesOverview />
                    <ChargePanelOverview />
                </ChargeDetailWrapper>
            )}
        </>
    )
}
