import { Box, Divider, HStack } from "@chakra-ui/react";
import { DetailHeading } from "../../components/DetailHeading";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import LocalTimeEntryCard from "../../components/LocalTimeEntryCard";
import { removeLocalEntry, selectLocalEntries } from "./localEntriesSlice";

interface TimeEntryPanelProps {
}

export function TimeEntryPanel({ }: TimeEntryPanelProps) {
    const dispatch = useAppDispatch()
    const localEntries = useAppSelector(selectLocalEntries)

    const handleRemove = (index: number) => {
        dispatch(removeLocalEntry(index))
    }

    return (
        <>
            {
                localEntries.length > 0 ?
                    <Box w={{ base: "98%", sm: "95%", md: "90%" }} maxW={"1440px"} p={2} mx="auto">
                        <DetailHeading title="Time Entry" />
                        <Box maxW={"90vw"} overflowX={"auto"}
                            sx={{
                                "&::-webkit-scrollbar": {
                                    width: "6px",
                                    height: "6px",
                                },
                                "&::-webkit-scrollbar-thumb": {
                                    backgroundColor: "gray.300",
                                    borderRadius: "4px",
                                },
                                "&::-webkit-scrollbar-thumb:hover": {
                                    backgroundColor: "gray.400",
                                },
                                "&::-webkit-scrollbar-track": {
                                    background: "transparent",
                                },
                            }}
                        >
                            <HStack my={4} gap={4} >
                                {
                                    localEntries.map((entry, index) => {
                                        return (
                                            <LocalTimeEntryCard
                                                key={index}
                                                index={index}
                                                timeEntry={entry}
                                            // onRemove={handleRemove}
                                            />
                                        )
                                    })
                                }
                            </HStack>
                        </Box>

                    </Box > :
                    null
            }
        </ >

    )
}
