import { useState } from 'react';
import { ModalForm } from './forms/ModalForm';
import { DetailHeading } from './DetailHeading';
import { OverlayLoader } from './OverlayLoader';
import { CustomButton } from './forms/CustomButton';
import { Box, HStack, useDisclosure } from '@chakra-ui/react';
import { useConfigCheck, useCustomToast } from '../app/hooks';
import { TimeEntryForm } from '../features/timeEntries/TimeEntryForm';
import { useCreateTimeEntryMutation } from '../features/timeEntries/timeEntriesApi';
import { StickyHeader } from './forms/fields/StickyHeader';
import { MainPageHeader } from './MainPageHeader';
import { BreadcrumbItemType } from './BreadCrumbs';
import { PageBanner } from './PageBanner';

export function TrackingPageHeader() {
    const { isOnline, userProfile } = useConfigCheck();
    const { showToast } = useCustomToast();
    const { isOpen, onClose, onOpen } = useDisclosure();
    const [createTimeEntry] = useCreateTimeEntryMutation();
    const [formIsSubmitting, setFormIsSubmiting] = useState<boolean>(false);

    const handleSave = async (data: any) => {
        setFormIsSubmiting?.(true)
        try {
            await createTimeEntry(data).unwrap()
            showToast({
                status: 'success',
                description: 'Successful submission of the time entry',
            });
        } catch (error) {
            showToast({
                status: 'error',
                description: 'Something went wrong',
            });
        } finally {
            setFormIsSubmiting?.(false)
        }
    };

    const breadCrumbItems: BreadcrumbItemType[] = [
        { label: 'Home', path: '/user/home', icon: 'home' },
        { label: 'Time Tracking', isCurrentPage: true }
    ];

    return (
        <>
            {
                userProfile?.role === "swamper" ?
                    <PageBanner title="Time Entries" helper="Summary" /> :
                    <>
                        <MainPageHeader breadCrumbItems={breadCrumbItems} />
                        <StickyHeader>
                            {formIsSubmitting && <OverlayLoader />}
                            <HStack w={"full"} alignItems={"center"} justifyContent={"space-between"} borderRadius={"4px"} >
                                <DetailHeading title="Time Tracking" />
                                <CustomButton title="Add New Entry" onClick={() => onOpen()} isDisabled={!isOnline} />
                            </HStack>

                            {/* {model for add time entry} */}
                            <ModalForm
                                title={`Add New Entry`}
                                isOpen={isOpen}
                                size={["full", "lg"]}
                                onClose={onClose}
                                onCancel={onClose}
                                onSave={handleSave}
                            >
                                <TimeEntryForm />
                            </ModalForm>
                        </StickyHeader >
                    </>
            }

        </>
    )
}
