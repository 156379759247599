import { useMemo } from 'react';
import { FieldProps } from "../../../forms";
import { useFormContext } from "react-hook-form";
import { CustomSelect } from './CustomeSelectField';
import { FormControl, FormLabel, FormHelperText, FormErrorMessage, BoxProps } from "@chakra-ui/react"
interface SelectFieldProps extends FieldProps, BoxProps {
  options: { value: number | string; label: string }[]
  isRequired?: boolean
  setIsValue?(data: number | null): void
  isClearable?: boolean
}

export function SelectField({
  label,
  name,
  registerOptions,
  helper,
  options,
  isRequired,
  setIsValue,
  isClearable = false,
  ...rest
}: SelectFieldProps) {
  const { register, formState: { errors }, setValue, watch, clearErrors, getValues } = useFormContext()
  const selectedValue = watch(name); // Track current value
  const equipmentValues = getValues("equipments");
  const defaultRegisterOptions = {
    required: isRequired ? "Please pick one" : false,
    valueAsNumber: options?.some((option) => typeof option.value === "number")
  }
  const mergedRegisterOptions = {
    ...defaultRegisterOptions,
    ...registerOptions,
  }

  // Format selected value for react-select
  const currentOption = options.find(
    (option) => option.value === selectedValue
  );

  const handleChange = (selectedOption: { value: number | string; label: string } | null) => {
    setIsValue?.(selectedOption ? Number(selectedOption.value) : null);
    setValue(name, selectedOption ? selectedOption.value : null);
    clearErrors(name)
  };


  //filter out already selected equipments
  const filteredOptions = useMemo(() => {
    return options.filter(item => !equipmentValues?.includes(item.value));
  }, [options, selectedValue, equipmentValues])

  return (
    <FormControl isInvalid={!!errors?.[name]} isRequired={isRequired} {...rest}>
      <FormLabel fontWeight={500} color={"#455360"}>{label}</FormLabel>
      <CustomSelect
        value={currentOption}
        name={name}
        register={register}
        registerOptions={mergedRegisterOptions}
        options={filteredOptions}
        placeholder="Pick One"
        handleChange={handleChange}
        errors={errors}
      />

      {/* </InputGroup> */}
      <FormHelperText>{helper}</FormHelperText>
      <FormErrorMessage>
        {errors?.[name]?.message?.toString()}
      </FormErrorMessage>
    </FormControl>
  )
}
