import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ModalForm } from './forms/ModalForm';
import { useNavigate } from 'react-router-dom';
import { DetailHeading } from './DetailHeading';
import { OverlayLoader } from './OverlayLoader';
import { KnownIcon } from './widgets/KnownIcon';
import { CustomButton } from './forms/CustomButton';
import { WrapperProps } from './TicketDetailWrapper';
import { Link as RouterLink } from "react-router-dom";
import { StampForm } from '../features/tickets/StampForm';
import { StickyHeader } from './forms/fields/StickyHeader';
import { TicketResource } from '../pages/user/TicketDetailPage';
import { usePartialUpdateTicketMutation } from '../features/tickets/ticketsApi';
import { saveDraftStampDetails, selectDraftStampDetails } from '../features/draft/draftSlice';
import { useAppSelector, useCustomToast, useConfigCheck, useUploadFileToS3 } from '../app/hooks';
import { ButtonGroup, Divider, HStack, IconButton, Menu, MenuButton, MenuItem, MenuList, Text, useDisclosure, useMediaQuery, VStack } from '@chakra-ui/react';
interface TicketDetailPageHeaderProps extends WrapperProps {
    dataresource?: TicketResource
}

export function TicketDetailPageHeader({
    dataresource: ticket,
    resourceid,
    isdraft
}: TicketDetailPageHeaderProps) {
    const { userProfile: { role } = {} } = useConfigCheck(); //get role of current user
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isOnline } = useConfigCheck();
    const { upload } = useUploadFileToS3();
    const { showToast } = useCustomToast();
    const { isOpen, onClose, onOpen } = useDisclosure();
    const [isLargerDevice] = useMediaQuery("(min-width: 992px)");
    const [formIsSubmitting, setIsFormSubmitting] = useState<boolean>(false);

    const draftStampDetails = useAppSelector(selectDraftStampDetails(Number(resourceid)))
    const [partialUpdateTicket, { isLoading: isUpdating }] = usePartialUpdateTicketMutation()

    const handleStatusChange = async (status: "approved" | "unapproved") => {
        const body = {
            id: Number(resourceid),
            status: ticket?.digital_signed ? "stamped" : status,
        };
        await partialUpdateTicket(body);
    };

    const stampDefaultValues = {
        cost_class: null,
        minor_class: null,
        major_class: null,
        other: null,
        digital_sign: null
    }

    const handelStampDetails = async (data: any) => {
        setIsFormSubmitting(true);
        const { digital_sign, other, minor_class, major_class, cost_class } = data;
        try {
            if (!isdraft) {
                const key = ticket?.number && await upload({ files: digital_sign, draftId: ticket?.number, folderType: "signature" })
                if (!key) throw new Error("Field to upload file in s3")
                // Prepare the payload for ticket update
                const payload = {
                    id: Number(resourceid),
                    cost_class,
                    minor_class,
                    major_class,
                    other,
                    digital_signed: true,
                    signature: key,
                    ...(role === "driver-lead" && { status: "stamped" }),
                };

                // Update ticket details
                const updateResponse = await partialUpdateTicket(payload);
                if (!("data" in updateResponse)) {
                    throw new Error(`Failed to update ticket details: ${JSON.stringify(updateResponse)}`);
                }
                // Show success toast message
                showToast({
                    status: 'success',
                    description: 'The client has successfully authorized the ticket and signed it',
                });
            } else {
                dispatch(saveDraftStampDetails({ id: resourceid, ...data }))
            }

        } catch (error) {
            console.error("Error during file upload process:", error);
            showToast({
                status: 'error',
                description: "There was an issue capturing the client's signature, Please try again",
            });
        } finally {
            setIsFormSubmitting(false);
        }
    };

    const handleCharge = () => {
        isdraft ? navigate(`/user/draft/${(Number(resourceid) || 0) + 1}/charges/`)
            : navigate(`/user/tickets/${resourceid}/charges/`)

    }

    //print href based on draft or ticket
    const printWithChargehref = isdraft ? `/print/draft/${(Number(resourceid) || 0) + 1}?withCharge=${true}` : `/print/tickets/${resourceid}?withCharge=${true}`
    const printWithOutChargehref = isdraft ? `/print/draft/${(Number(resourceid) || 0) + 1}?withCharge=${false}` : `/print/tickets/${resourceid}?withCharge=${false}`

    const isButtonDisabled = !(isOnline || isdraft);  //diable button based on network connectivity and draft or ticket

    return (
        <>
            {formIsSubmitting && <OverlayLoader />}
            <StickyHeader>
                <VStack w={"full"} gap={0} alignItems={"flex-start"}>
                    <HStack gap={4} alignItems={"stretch"}  >
                        <DetailHeading title={isdraft ? 'Ticket Draft' : `#${ticket?.number}`} fontSize={{ base: "1.5rem", lg: "2rem" }} lineHeight={'normal'} />
                        {!isdraft &&
                            (role !== "customer") &&
                            (ticket?.status === "submitted" || ticket?.status === "unapproved") && (
                                <CustomButton
                                    title="Edit"
                                    variant="outline"
                                    fontSize={"14px"}
                                    size={"xs"}
                                    w={undefined}
                                    isDisabled={isButtonDisabled}
                                    onClick={() => navigate(`/user/tickets/${resourceid}/edit/`)}
                                />
                            )}
                    </HStack>
                    <Text fontWeight={400} fontSize={{ base: "12px", lg: "14px" }} color={"#737791"} >{isdraft ? 'This ticket has not yet been saved to the cloud' : 'Ticket Details'}</Text>
                </VStack>
                {
                    isLargerDevice ?
                        <ButtonGroup gap={2}>
                            {
                                ((role === "driver" && ticket?.status == "submitted" && !ticket?.digital_signed) || isdraft) &&
                                <CustomButton title="Add Signature" onClick={() => onOpen()} />
                            }

                            {(((role !== "customer") && (ticket?.status == "submitted" || ticket?.status == "unapproved")) || isdraft) && (
                                <CustomButton title="Add Charges" onClick={handleCharge} isDisabled={isButtonDisabled} />
                            )}
                            <CustomButton title="Print with" rightIcon={<KnownIcon name="dollar" size={5} />} href={printWithChargehref} isDisabled={isButtonDisabled} target={"_blank"} />
                            <CustomButton title="Print without" rightIcon={<KnownIcon name="dollar" size={5} />} href={printWithOutChargehref} isDisabled={isButtonDisabled} target={"_blank"} />
                            {
                                (role !== "customer" && role !== "driver") &&
                                (ticket?.status === "submitted" || ticket?.status === "unapproved") &&
                                <CustomButton title="Approve"
                                    isLoading={isUpdating}
                                    onClick={() => handleStatusChange("approved")}
                                    isDisabled={isButtonDisabled}
                                />
                            }
                            {((role !== "customer" && role !== "driver") && ticket?.status === "approved") && (
                                <CustomButton title="Unapprove"
                                    isLoading={isUpdating}
                                    onClick={() => handleStatusChange("unapproved")}
                                    isDisabled={isButtonDisabled}
                                />
                            )}
                        </ButtonGroup>
                        :
                        <Menu>
                            <MenuButton
                                as={IconButton}
                                icon={<KnownIcon name='options' boxSize="24px" />}
                                aria-label='Options'
                                background="transparent"
                                isDisabled={isButtonDisabled}
                            />
                            <MenuList color={"#455360"}>

                                {(((role !== "customer") && (ticket?.status == "submitted" || ticket?.status == "unapproved")) || isdraft) && (
                                    <>
                                        <MenuItem onClick={handleCharge} isDisabled={isButtonDisabled}>
                                            Add Charges
                                        </MenuItem>
                                        <Divider bgColor={"#F0F0F0"} />
                                    </>
                                )}
                                {
                                    ((role === "driver" && ticket?.status == "submitted" && !ticket?.digital_signed) || isdraft) &&
                                    <>
                                        <MenuItem onClick={() => onOpen()}>
                                            Add Signature
                                        </MenuItem>
                                        <Divider bgColor={"#F0F0F0"} />
                                    </>
                                }
                                <MenuItem as={RouterLink} to={printWithChargehref} isDisabled={isButtonDisabled} target={"_blank"}>
                                    <HStack alignItems={'center'}>
                                        <Text>Print With</Text>
                                        <KnownIcon name="darkDollar" size={5} />
                                    </HStack>
                                </MenuItem>
                                <Divider bgColor={"#F0F0F0"} />
                                <MenuItem as={RouterLink} to={printWithOutChargehref} isDisabled={isButtonDisabled} target={"_blank"}>
                                    <HStack alignItems={'center'}>
                                        <Text>Print Without </Text>
                                        <KnownIcon name="darkDollar" size={5} />
                                    </HStack>
                                </MenuItem>
                                {
                                    (role !== "customer" && role !== "driver") &&
                                    (ticket?.status === "submitted" || ticket?.status === "unapproved") &&
                                    <>
                                        <Divider bgColor={"#F0F0F0"} />
                                        <MenuItem onClick={() => handleStatusChange("approved")} isDisabled={isButtonDisabled}>
                                            Approve
                                        </MenuItem>
                                    </>
                                }
                                {((role !== "customer" && role !== "driver") && ticket?.status === "approved") && (
                                    <>
                                        <Divider bgColor={"#F0F0F0"} />
                                        <MenuItem onClick={() => handleStatusChange("unapproved")}>
                                            Unapprove
                                        </MenuItem>
                                    </>
                                )}
                            </MenuList>
                        </Menu>
                }

                <ModalForm
                    title={`Stamp details`}
                    defaultValues={isdraft ? draftStampDetails : stampDefaultValues}
                    isOpen={isOpen}
                    size={["full", "lg"]}
                    onClose={onClose}
                    onCancel={onClose}
                    onSave={handelStampDetails}
                >
                    <StampForm />
                </ModalForm>
            </StickyHeader>
        </>
    )
}
