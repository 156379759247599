import { useEffect, useState } from "react";
import { PuffLoader } from "react-spinners";
import { useParams } from "react-router-dom";
import { Stream } from "../../components/Stream";
import { ErrorBox } from "../../components/ErrorBox";
import { PrintContent } from "../print/TicketDetailPrint";
import { StampForm } from "../../features/tickets/StampForm";
import { ModalForm } from "../../components/forms/ModalForm";
import { OverlayLoader } from "../../components/OverlayLoader";
import { BreadcrumbItemType } from "../../components/BreadCrumbs";
import { CustomButton } from "../../components/forms/CustomButton";
import { StampTicketHeader } from "../../components/StampTicketHeader";
import { Attachment } from "../../components/forms/fields/AttachmentDropZone";
import { ImageWithSkeleton } from "../../components/forms/fields/ImageWithSkeleton";
import { useCustomToast, useConfigCheck, useUploadFileToS3 } from "../../app/hooks";
import { useRetriveAttachmentsQuery, useRetriveSignatureQuery } from "../../features/aws/awsApi";
import { Box, ButtonGroup, Flex, Text, useDisclosure, useMediaQuery, VStack } from "@chakra-ui/react";
import { usePartialUpdateTicketMutation, useRetrieveTicketQuery } from "../../features/tickets/ticketsApi";

interface PrintContentProps {

}

export function StampTicketDetail({ }: PrintContentProps) {
    const { id } = useParams();
    const { isOnline } = useConfigCheck();
    const { showToast } = useCustomToast();
    const { upload } = useUploadFileToS3();
    const { isOpen, onClose, onOpen } = useDisclosure();
    const [networkError, setNetworkError] = useState("");
    const [isFormSubmit, setIsFormSubmit] = useState<boolean>(false);
    const [isLargerThanMobile] = useMediaQuery("(min-width: 768px)");

    const [partialUpdateTicket] = usePartialUpdateTicketMutation();

    //retrive ticket
    const { data: ticket, isLoading, isError, error } = useRetrieveTicketQuery(Number(id));
    const ticketNumber = ticket?.number;
    const ticketStatus = ticket?.status || "";
    const isDigitalSigned = ticket?.digital_signed || false;
    const shouldSkipAttachmentsQuery = isLoading || isError || !ticketNumber;

    const { data: attachments, isLoading: isAttachmentLoading } = useRetriveAttachmentsQuery(ticketNumber, { skip: shouldSkipAttachmentsQuery });
    const { data: signatureData, isLoading: isSignatureDataLoading } = useRetriveSignatureQuery(ticketNumber, { skip: !(ticketNumber && ticket.digital_signed) });

    const defaultValues = {
        cost_class: null,
        minor_class: null,
        major_class: null,
        other: null,
        digital_sign: null
    }

    const handelStampDetails = async (data: any) => {
        setIsFormSubmit(true);
        const { digital_sign, other, minor_class, major_class, cost_class } = data;
        try {
            const key = ticketNumber && await upload({
                files: digital_sign,
                folderType: "signature",
                draftId: ticketNumber
            })
            if (!key) throw new Error("Field to upload file in s3")
            const payload = {
                id: Number(id),
                cost_class,
                minor_class,
                major_class,
                other,
                digital_signed: true,
                signature: key,
                status: "stamped",
            };
            // Update ticket details
            const updateResponse = await partialUpdateTicket(payload);
            if (!("data" in updateResponse)) {
                throw new Error(`Failed to update ticket details: ${JSON.stringify(updateResponse)}`);
            }
            // Show success toast message
            showToast({
                status: "success",
                description:
                    "You have successfully authorized the ticket",
            });
        } catch (error) {
            console.error("Error during file upload process:", error);
            showToast({
                status: "error",
                description:
                    "An error occurred, Please try again",
            });
        } finally {
            setIsFormSubmit(false);
        }
    };

    useEffect(() => {
        if (error && 'status' in error && error.status === 'FETCH_ERROR') {
            setNetworkError("Ticket details are not available without network connectivity");
            window.scrollTo({ top: 0, behavior: "smooth" })
        }
    }, [isError])

    const breadcrumbItems: BreadcrumbItemType[] = [
        { label: 'All Tickets', path: '/user/tickets', icon: 'home' },
        { label: `#${ticketNumber || ''}`, isCurrentPage: true }, // No path since it's the current page
    ];

    return (
        <Box w="full">
            <StampTicketHeader
                isLoading={isLoading}
                onOpen={onOpen}
                ticketStatus={ticketStatus}
                isDigitalSigned={isDigitalSigned}
                breadCrumbItems={breadcrumbItems}
            />
            <VStack w={"90%"} p={2} gap={4} alignItems={"flex-start"} mx="auto" >
                <ErrorBox show={isError ? true : false} error={error} message={networkError} />
                {
                    !isError &&
                    <>
                        < Box w={"100%"} overflowX={"auto"} maxW={"calc(100vw - 50px)"} border="2px solid #bee3f8" borderRadius={"5px"} bgColor={"#fff"}>
                            {
                                (!isLoading && !isSignatureDataLoading && ticket) ?
                                    <Box w="90%" minW="750px" minH={"750px"} my={10} mx={"auto"}>
                                        <PrintContent ticket={ticket} charges={ticket?.chargedetails || []} signatureData={signatureData?.[0]} />
                                    </Box>
                                    :
                                    <Flex w="full" minH={"750px"} alignItems="center" justifyContent="center">
                                        <PuffLoader
                                            color="#3D82CE"
                                            size={80}
                                        />
                                    </Flex>
                            }
                        </Box>
                        <VStack w="full" spacing={4} p={5} alignItems="flex-start">
                            <Stream title="Attachments" color={"#05004E"}>
                                <Flex gap={4} my={4} wrap="wrap" w="full">
                                    {
                                        !isAttachmentLoading ?
                                            attachments && attachments?.length > 0 ? attachments?.map((attachment: Attachment) => (
                                                <Box position="relative" key={attachment.file_key}>
                                                    <ImageWithSkeleton
                                                        key={attachment.file_key}
                                                        attachment={attachment}
                                                        showRemoveIcon={false}
                                                    />
                                                </Box>
                                            )) : (
                                                <Text fontSize="14px" color={"#666E82"}>{`There are no attachments`}</Text>
                                            )
                                            :
                                            <Flex w="full" justifyContent="center" alignItems="center">
                                                <PuffLoader
                                                    color="#3D82CE"
                                                    size={80}
                                                />
                                            </Flex>
                                    }
                                </Flex>
                            </Stream>
                        </VStack>

                    </>
                }
            </VStack >
            {
                !isLargerThanMobile &&
                <Box bgColor={"#fff"} minH={"100px"} alignContent={"center"}>
                    <VStack w={"90%"} mx={"auto"}>
                        <ButtonGroup gap={["20px", "25px"]}>
                            {ticketStatus === "approved" && !isDigitalSigned && (
                                <CustomButton title="Stamp" onClick={() => onOpen()} />
                            )}
                            <CustomButton title="Print" href={isOnline ? `/print/tickets/${id}?withCharge=${true}` : undefined} isDisabled={isOnline ? false : true} />
                        </ButtonGroup>
                    </VStack>
                </Box>
            }
            <ModalForm
                title={`Stamp details`}
                defaultValues={defaultValues}
                isOpen={isOpen}
                size={["full", "lg"]}
                onClose={onClose}
                onCancel={onClose}
                onSave={handelStampDetails}
            >
                <StampForm />
            </ModalForm>
            {isFormSubmit && <OverlayLoader />}
        </Box>

    )
}


