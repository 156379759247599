import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { CardView } from "../../components/CardView";
import { DraftCard } from "../../components/DraftCard";
import { Container } from "../../components/Container";
import { Draft } from "../../features/draft/draftSlice";
import { PageBanner } from "../../components/PageBanner";
import { Box, HStack, SimpleGrid } from "@chakra-ui/react";
import { DetailHeading } from "../../components/DetailHeading";
import { useConfigCheck, useAppSelector } from "../../app/hooks";
import { LatestTickets } from "../../features/tickets/LatestTickets";
import { useListProductsQuery } from "../../features/products/productsApi";
import { useListServicesQuery } from "../../features/services/servicesApi";
import { selectCustomerById } from "../../features/customers/customersSlice";
import { selectTerminalById } from "../../features/terminals/terminalsSlice";
import { useListCustomersQuery } from "../../features/customers/customersApi";
import { useListLocationsQuery } from "../../features/terminals/locationsApi";
import { useListTerminalsQuery } from "../../features/terminals/terminalsApi";
import { useListEquipmentsQuery } from "../../features/equipments/equipmentsApi";
import { useListActivitiesQuery } from "../../features/activities/activitiesApi";

interface type {
    data: Draft
    index: number
}
function CurrentDraftTapTile({ data, index }: type) {
    const { hasContent, basic, locations } = data
    const fallbackCustomerName = useAppSelector(
        (state) => selectCustomerById(state, basic?.customer || 0)?.name,
    )
    const customerName = basic?.customer
        ? typeof basic?.customer == "number"
            ? `Customer "${fallbackCustomerName}"`
            : `New Customer "${basic?.customer}"`
        : "No Customer Selected"

    const fallbackTerminalName = useAppSelector(
        (state) => selectTerminalById(state, locations?.consignee?.terminal || 0)?.name,
    )
    const terminalName = locations?.consignee?.terminal
        ? typeof locations?.consignee?.terminal == "number"
            ? `Terminal "${fallbackTerminalName}"`
            : `New Terminal "${locations?.consignee?.terminal}"`
        : "No Terminal Selected"

    return (
        hasContent && (
            <DraftCard
                title={customerName}
                subtitle={terminalName}
                draftNumber={data?.number ?? null}
                href={`/user/draft/${++index}`}
            />
        )
    )
}

export function DriverHomePage() {
    const navigate = useNavigate();
    useListProductsQuery();
    useListServicesQuery();
    useListCustomersQuery();
    useListTerminalsQuery();
    useListLocationsQuery();
    useListActivitiesQuery();
    useListEquipmentsQuery();
    const draftsArray = useAppSelector((state) => state.drafts.drafts);
    const { userProfile: { role } = {} } = useConfigCheck(); //get role of current user

    // below code is for horizontal scroll on mouse wheel    
    const scrollRef = useRef<HTMLDivElement | null>(null);

    const handleWheel = (event: WheelEvent): void => {
        if (scrollRef.current) {
            const container = scrollRef.current;
            // Check if the content is scrollable horizontally
            const isContentScrollableHorizontally = container.scrollWidth > container.clientWidth;
            if (isContentScrollableHorizontally) {
                event.preventDefault(); // Prevent vertical scroll
                container.scrollLeft += event.deltaY; // Scroll horizontally
            }
        }
    };

    useEffect(() => {
        const container = scrollRef.current;
        if (container) {
            // Add wheel event with non-passive listener
            container.addEventListener('wheel', handleWheel, { passive: false });
        }
        // Cleanup the event listener on component unmount
        return () => {
            if (container) {
                container.removeEventListener('wheel', handleWheel);
            }
        };
    }, []);

    return (
        <>
            <PageBanner title={'Home'} helper="Summary" />
            <Container direction="column">
                <SimpleGrid
                    w="full"
                    gap={4}
                    minChildWidth={{ base: "full", sm: "330px" }}
                >
                    <CardView
                        title="Time Tracking"
                        helper="Add and view your hours here"
                        onClick={() => navigate("/user/tracking")}
                    />
                    <CardView
                        title="Create New Ticket"
                        helper="Create your ticket"
                        onClick={() =>
                            draftsArray.length !== 0
                                ? navigate(`/user/draft/${draftsArray.length + 1}`)
                                : navigate(`/user/draft`)
                        }
                    />
                </SimpleGrid>
                {
                    draftsArray.length > 0 &&
                    < Box w="full">
                        <DetailHeading title="Drafts" lineHeight={"normal"} />
                        <Box ref={scrollRef} maxW={"90vw"} overflowX={"auto"} className="custom-scrollbar" >
                            <HStack mt={2} mb={1} gap={4} p={2} >
                                {draftsArray &&
                                    draftsArray.map((draft, index) => {
                                        return (
                                            <CurrentDraftTapTile data={draft} index={index} key={index} />
                                        )
                                    })}
                            </HStack>
                        </Box>
                    </Box>
                }
                <LatestTickets limit={5} />
            </Container >
        </>
    )
}
