import { useMemo } from "react";
import { FieldProps } from "../../forms";
import { OptionType } from "../tickets/tickets";
import { useFormContext } from "react-hook-form";
import { useAppSelector } from "../../app/hooks";
import { selectAllCustomers } from "./customersSlice";
import { CustomSelect } from "../../components/forms/fields/CustomeSelectField";
import { FormControl, FormErrorMessage, FormHelperText, FormLabel, VStack } from "@chakra-ui/react";

interface CustomerAutocompleteProps extends FieldProps { }

export function CustomerAutocomplete({
  name,
  label,
  helper,
  registerOptions,
}: CustomerAutocompleteProps) {
  //get all customers
  const customers = useAppSelector(selectAllCustomers)

  //options for select field
  const suggestions = useMemo(() => {
    const filteredCustomers = customers.filter(customer => customer?.profile !== null);
    return filteredCustomers.map(customer => ({
      value: customer.id,
      label: `${customer?.name}`,
    }));
  }, [customers]);


  const { formState: { errors }, setValue, register, trigger, watch } = useFormContext()
  const selectedValue = watch(name); // Track current value

  // Format selected value for react-select
  const currentOption = suggestions.find(
    (item) => item.value === selectedValue
  );

  const handleSelect = (selectedOption: OptionType | null) => {
    setValue(name, selectedOption ? selectedOption.value : null);
    trigger(name)

    //set consignor_contact null if customer fieeld is changing
    setValue("consignor_contact", null);

  };

  return (
    <FormControl isInvalid={errors[name] !== undefined} isRequired>
      <VStack w="full" gap="10px" alignItems="flex-start">
        <FormLabel color="#455360">{label}</FormLabel>
        <CustomSelect
          value={currentOption}
          name={name}
          register={register}
          registerOptions={registerOptions}
          options={suggestions}
          placeholder="Select customer"
          handleChange={handleSelect}
          errors={errors}
          isClearable={true}
          isSearchable={true}
          icon="blueUser"
        />
        {helper && <FormHelperText>{helper}</FormHelperText>}
        <FormErrorMessage>{errors[name]?.message?.toString()}</FormErrorMessage>
      </VStack>

    </FormControl >
  )
}
