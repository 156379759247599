import { DetailTile } from "../../components/DetailTile"
import { PanelWrapper } from "../../components/PanelWrapper"
import { Box, HStack, Stack, StackDivider, useMediaQuery, VStack } from "@chakra-ui/react"
import { WrapperProps } from "../../components/TicketDetailWrapper"
import { TicketResource } from "../../pages/user/TicketDetailPage"

interface UnloadInfoPanelProps extends WrapperProps {
    dataresource?: TicketResource
}
export function UnloadInfoPanel({
    dataresource: ticket
}: UnloadInfoPanelProps) {
    const [isBreakPoint] = useMediaQuery("(max-width: 700px)")

    return (
        <Box w={{ base: "98%", sm: "95%", md: "90%" }} maxW={"1440px"} p={2} mx="auto">
            <PanelWrapper title="Unload">
                <VStack align="stretch" gap={5} p={2} divider={<StackDivider />}>
                    <Stack direction={isBreakPoint ? "column" : "row"} w={"full"} alignItems="flex-start" gap={isBreakPoint ? 3 : "60px"} divider={<StackDivider />}>
                        <DetailTile flexBasis={"20%"} title="Actual Volume" detail={ticket?.actual_volume} />
                        <DetailTile title="Sequence" detail={ticket?.sequence} />
                        <DetailTile title="SW Percent" detail={ticket?.sw_percent} />
                    </Stack>
                    <Stack direction={"row"} w="full" gap={{ base: "20px", lg: "60px" }} divider={<StackDivider />}>
                        <DetailTile flex={1} title="Description of Work" detail={ticket?.work_description} />
                    </Stack>
                </VStack>
            </PanelWrapper>
        </Box>
    )
}

