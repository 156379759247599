import { useNavigate } from 'react-router-dom';
import { KnownIcon } from './widgets/KnownIcon';
import { Heading, HStack, Text, VStack } from '@chakra-ui/react';

interface DraftTapTileProps {
    title: string
    subtitle?: string
    signature?: string
    href: string
    draftNumber?: number | null
    children?: React.ReactNode
}

export function DraftCard({
    title,
    subtitle,
    href,
    draftNumber,
}: DraftTapTileProps) {
    const navigate = useNavigate()
    return (
        <>
            <VStack
                className='scale-effect'
                flexShrink={0}
                cursor={"pointer"}
                bgColor={"#EBEEFF"}
                alignItems={"flex-start"}
                p={4} borderRadius={"6px"}
                onClick={() => navigate(href)}
                w={{ base: "280px", sm: "320px", lg: "400px" }}
            >
                <HStack w="full" alignItems={"center"} justifyContent={"space-between"}>
                    <Heading fontSize={"18px"} fontWeight={600} color={"#0E1628"} lineHeight={"1.5rem"}>#{draftNumber}</Heading>
                    <KnownIcon name='link' boxSize={"1rem"} />
                </HStack>
                <Text
                    w="85%"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    fontWeight={500}
                    color={"#05004E"}
                    fontSize={{ base: "14px", sm: "16px", lg: "18px" }}
                    isTruncated
                >
                    {title}
                </Text>
                <Text color={"#666E82"} w="90%" whiteSpace="nowrap" fontWeight={400} fontSize={"14px"} isTruncated>{subtitle}</Text>
            </VStack >
        </>
    )
}
