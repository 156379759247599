import { Box, HStack } from "@chakra-ui/react"
import { ReactNode, useEffect, useState } from "react";

interface StickeHeaderProps {
    children?: ReactNode | ReactNode[]
}

export function StickyHeader({ children }: StickeHeaderProps) {
    const [isSticky, setIsSticky] = useState(() => window.scrollY > 0); // Initialize based on scroll position

    useEffect(() => {
        // Set `isSticky` immediately on mount based on current scroll position
        const updateStickyState = () => setIsSticky(window.scrollY > 0);

        updateStickyState(); // Check scroll position on load
        window.addEventListener("scroll", updateStickyState);

        return () => {
            window.removeEventListener("scroll", updateStickyState);
        };
    }, []);

    return (
        <>
            <Box
                mx={"auto"}
                position={isSticky ? "sticky" : "relative"}
                w={isSticky ? "100%" : { base: "95%", md: "90%" }} // When sticky, make it full width
                top={isSticky ? "85px" : undefined}
                left="0"
                transition={"all 0.4s ease"}
                bg={"#F8F8FB"}
                zIndex={100}
            >
                <Box
                    h="80px"
                    w={"full"}
                    mt={1}
                    py={3}
                    px={isSticky ? { base: 2, md: 5 } : 0}
                    bg={isSticky ? "#EBEEFF" : "transparent"}
                    transition="background-color 0.4s ease, padding 0.4s ease"
                >
                    <HStack
                        mx={"auto"}
                        maxW={"1440px"}
                    // w={"full"}
                    // h="80px"
                    // mt={1}
                    // py={3}
                    // px={isSticky ? { base: 2, md: 5 } : 0}
                    // bg={isSticky ? "#EBEEFF" : "transparent"}
                    // transition="background-color 0.4s ease, padding 0.4s ease"
                    // justifyContent={"space-between"}
                    >
                        {children}
                    </HStack>
                </Box>
            </Box >

        </>
    )
}

