import { Divider } from "@chakra-ui/react";
import { SubmittedTimeEntries } from "./SubmittedTimeEntries";
import { TrackingPageHeader } from "../../components/TrackingPageHeader";
import { TimeEntryPanel } from "../../features/timeEntries/TimeEntryPanel";

export function TrackingPage() {
    return (
        <>
            <TrackingPageHeader />
            <TimeEntryPanel />
            <Divider w={{ base: "98%", sm: "95%", md: "90%" }} maxW={"1440px"} mx="auto" color={"#D3D3D3"} my={3} />
            <SubmittedTimeEntries />
        </>
    )
}
