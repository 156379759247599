import { components } from "../../app/api/schema";
import { DetailTile } from "../../components/DetailTile";
import { PanelWrapper } from "../../components/PanelWrapper";
import { TicketResource } from "../../pages/user/TicketDetailPage";
import { Box, Stack, StackDivider, VStack } from "@chakra-ui/react";
import { WrapperProps } from "../../components/TicketDetailWrapper";

type ServiceDetailsResource = components["schemas"]["Service"]

interface ServicePanelProps extends WrapperProps {
    dataresource?: TicketResource
}

export function ServicesPanel({
    dataresource: ticket
}: ServicePanelProps) {
    return (
        <Box w={{ base: "98%", sm: "95%", md: "90%" }} maxW={"1440px"} p={2} mx="auto">
            <PanelWrapper title="Additional Services">
                <VStack align="stretch" gap={{ base: 0, lg: 2 }} p={2} divider={<StackDivider />}>
                    {
                        (ticket && ticket?.services?.length > 0) ? ticket?.services.map((service: ServiceDetailsResource, index: number) => (
                            <VStack key={index} align="stretch" gap={{ base: 0, lg: 2 }} p={2} divider={<StackDivider />}>
                                <Stack direction={"row"} w="full" gap={6} divider={<StackDivider />}>
                                    <DetailTile
                                        flexBasis={"50%"}
                                        title="Service Name"
                                        detail={service.name}
                                    />
                                    <DetailTile
                                        title="Qty"
                                        detail={ticket?.servicedetails?.[index]?.quantity}
                                    />
                                </Stack>
                                <DetailTile
                                    flexBasis={"60%"}
                                    title="Description"
                                    detail={ticket?.servicedetails?.[index]?.description}
                                />
                            </VStack>
                        )) :
                            <VStack align="stretch" >
                                <Box fontSize="14px" color="#455360">
                                    No additional services have been added
                                </Box>
                            </VStack>

                    }
                </VStack>
            </PanelWrapper >
        </Box>
    )
}

