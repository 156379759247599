import { Box, Flex, HStack } from '@chakra-ui/react'
import React from 'react'

interface TicketHeaderProps {
    children: React.ReactNode | React.ReactNode[]
}

export function TicketHeaderBox({ children }: TicketHeaderProps) {
    return (
        <Flex w="full" h={"40px"} bgColor={"#EBEEFF"} alignItems={"center"}
            position={"sticky"} top={"45px"} zIndex={100}
        >
            <HStack w={"90%"} p={2} gap={4} alignItems={"center"} mx="auto" justifyContent={"space-between"} maxW={"1440px"} >
                {children}
            </HStack>
        </Flex >
    )
}
