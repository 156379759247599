import { useDispatch } from "react-redux";
import { Draft } from "../draft/draftSlice";
import { useAppSelector } from "../../app/hooks";
import { Box, useMediaQuery } from "@chakra-ui/react";
import { Container } from "../../components/Container";
import DriverHoursForm_NEW from "../draft/DriverHoursForm";
import { PanelWrapper } from "../../components/PanelWrapper";
import { forwardRef, useEffect, useMemo, useState } from "react";
import { WrapperProps } from "../../components/TicketDetailWrapper";
import { addLocalEntry, LocalEntry, removeLocalEntriesbyActivity, selectLocalEntries } from "./localEntriesSlice";

interface AdditionalItemsPanelProps extends WrapperProps {
    dataresource?: Draft
}

export const DriverHoursPanel = forwardRef(
    function DriverHoursPanel({ dataresource = {} as Draft, resourceid, isdraft }: AdditionalItemsPanelProps, ref) {
        const dispatch = useDispatch()
        const [isSmallScreen] = useMediaQuery("(max-width: 468px)")

        const { number } = dataresource

        //check for there is time entries in current draft
        const [draftDriverHourEntries, setDraftDriverHourEntries] = useState<LocalEntry[]>([])
        const localEntries = useAppSelector(selectLocalEntries)
        const filteredTimeEntries = useMemo(() => localEntries.filter((item: LocalEntry) => item.ticketDraft === dataresource?.number), [dataresource?.number, localEntries])
        useEffect(() => setDraftDriverHourEntries(filteredTimeEntries), [filteredTimeEntries])

        //data save handler
        const handleTimeEntrySave = (data: any) => {
            dispatch(addLocalEntry({ ticketDraft: dataresource?.number, ...data }))
        }

        //remove handler
        const handleTimeEntryRemove = (activity: number) => {
            const filteredEntries = localEntries.filter((item) => {
                return !(item.activity == activity && item.ticketDraft == number)
            })
            dispatch(removeLocalEntriesbyActivity(filteredEntries))
        }

        return (
            <Box w="full" >
                <Container condensed={!isSmallScreen}>
                    <PanelWrapper title="Driver Hours">
                        <DriverHoursForm_NEW timeEntries={draftDriverHourEntries} onSave={handleTimeEntrySave} onRemove={handleTimeEntryRemove} isdraft={isdraft} />
                    </PanelWrapper>
                </Container>
            </Box >
        )
    })
