import { FieldProps } from "../../forms";
import { ReactNode, useEffect } from "react";
import { CustomButton } from "./CustomButton";
import { useFieldArray } from "react-hook-form";
import { KnownIcon } from "../widgets/KnownIcon";
import { Box, IconButton, Text } from "@chakra-ui/react";

interface FieldArrayProps extends FieldProps {
  isValue?: number | null
  onRemove?(index: number): void
  setIsValue(data: number | null): void
  children: (fieldName: string) => ReactNode
}

export function FieldArray({ name, children, onRemove, isValue, setIsValue }: FieldArrayProps) {
  const { fields, append, remove } = useFieldArray({ name })
  useEffect(() => {
    if (fields.length === 0) {
      append(null)
    }
  }, [append, fields])

  const handleRemove = (index: number) => {
    remove(index)
    onRemove && onRemove(index)
  }
  const handleAdd = () => {
    append(null)
    setIsValue(null)
  }

  return (
    <Box w="full">
      <Box display="flex" alignItems="center" gap={{ base: 4, md: 8 }}>
        <Text w={{ base: "70%", md: "50%" }} fontWeight="500" fontSize="18px" color="#05004E">
          Equipment
        </Text>
        <CustomButton
          size="sm"
          title="Add"
          variant="outline"
          onClick={handleAdd}
          isDisabled={!isValue}
          w={{ base: "40px", md: "80px" }}
          visibility={fields.length == import.meta.env.VITE_EQUIPMENT_LENGTH ? "hidden" : "visible"}
        />
      </Box>
      {fields.map((field, index) => (
        <Box key={field.id} display="flex" alignItems="center" gap={{ base: 4, md: 8 }}>
          {children(`${name}[${index}]`)}
          <IconButton
            aria-label="Remove"
            backgroundColor="transparent"
            onClick={() => handleRemove(index)}
            _hover={{ backgroundColor: "transparent" }}
            isDisabled={fields.length === 1 && index === 0}
            icon={<KnownIcon name="delete" boxSize="24px" cursor="pointer" />}
          />
        </Box>
      ))}
    </Box>

  )
}
