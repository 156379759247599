import storage from "redux-persist/lib/storage"
import { persistReducer, persistStore } from "redux-persist"
import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit"

import { bolmintApi } from "./api"
import authReducer from "../app/auth"
import draftReducer from "../features/draft/draftSlice"
import { setupListeners } from "@reduxjs/toolkit/query"
import sessionReducer from "../features/session/sessionSlice"
import chargesReducer from "../features/charges/chargesSlice"
import ticketsReducer from "../features/tickets/ticketsSlice"
import productsReducer from "../features/products/productsSlice"
import servicesReducer from "../features/services/servicesSlice"
import customersReducer from "../features/customers/customersSlice"
import locationsReducer from "../features/terminals/locationsSlice"
import terminalsReducer from "../features/terminals/terminalsSlice"
import activitiesReducer from "../features/activities/activitySlice"
import compConfigReducer from "../features/compConfig/compConfigSlice"
import equipmentsReducer from "../features/equipments/equipmentsSlice"
import timeEntriesReducer from "../features/timeEntries/timeEntriesSlice"
import localEntriesReducer from "../features/timeEntries/localEntriesSlice"

const persistedDraftReducer = persistReducer(
  { key: "draft", storage },
  draftReducer,
)

const persistedLocalEntriesReducer = persistReducer(
  { key: "localEntries", storage },
  localEntriesReducer,
)

const persistedCustomersReducer = persistReducer(
  { key: "customers", storage },
  customersReducer,
)

const persistedLocationsReducer = persistReducer(
  { key: "locations", storage },
  locationsReducer,
)

const persistedTerminalsReducer = persistReducer(
  { key: "terminals", storage },
  terminalsReducer,
)

const persistedEquipmentsReducer = persistReducer(
  { key: "equipments", storage },
  equipmentsReducer,
)

const persistedProductsReducer = persistReducer(
  { key: "products", storage },
  productsReducer,
)

const persistedServicesReducer = persistReducer(
  { key: "services", storage },
  servicesReducer,
)

const persistedActivitiesReducer = persistReducer(
  { key: "activities", storage },
  activitiesReducer,
)

const persistedCompConfigReducer = persistReducer(
  { key: "compConfig", storage },
  compConfigReducer,
)

export const store = configureStore({
  reducer: {
    [bolmintApi.reducerPath]: bolmintApi.reducer,
    auth: authReducer,
    compConfig: persistedCompConfigReducer,
    activities: persistedActivitiesReducer,
    session: sessionReducer,
    drafts: persistedDraftReducer,
    customers: persistedCustomersReducer,
    locations: persistedLocationsReducer,
    terminals: persistedTerminalsReducer,
    equipments: persistedEquipmentsReducer,
    products: persistedProductsReducer,
    services: persistedServicesReducer,
    tickets: ticketsReducer,
    timeEntries: timeEntriesReducer,
    localEntries: persistedLocalEntriesReducer,
    charges: chargesReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(bolmintApi.middleware),
})
setupListeners(store.dispatch)
export const persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
