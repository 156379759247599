import { useFormContext } from "react-hook-form"

interface FormProps {
    defaultValues?: any
    onSave(data: any): void
    children: React.ReactNode | React.ReactNode[]
}

export function EditForm({ onSave, children }: FormProps) {

    return (
        <form style={{ width: "100%" }} onSubmit={onSave} >{children}</form>
    )
}
