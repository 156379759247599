import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux"
import type { RootState, AppDispatch } from "./store"
import { useToast, Box, CloseButton } from '@chakra-ui/react';
import { FaCheckCircle, FaExclamationCircle } from 'react-icons/fa';
import { ReactNode, useEffect } from "react"
import { useGetPresignedUrlMutation, useUploadFileToS3Mutation } from "../features/aws/awsApi";
import { useContext, useRef, useCallback, useState } from "react"
import { ConfigContext } from "../contaxt/ConfigProvider";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export type TraitSelector = (state: RootState, id: number) => any | undefined
type TraitGetter = (obj: any) => string
type ToastOptions = {
  title?: string;
  description: string;
  status: 'success' | 'error' | 'info' | 'warning';
  bgColor?: string;
  icon?: ReactNode;
  isClosable?: boolean
  duration?: number | null
  onCloseCallback?: () => void
}


interface TraitResult {
  trait: string;
  isNew: boolean;
}

export function useTrait(
  getter: TraitGetter,
  id: ExistingOrNew | undefined,
  selector: TraitSelector,
): TraitResult | undefined {
  const isNew = typeof id === "string" && id.trim().length > 0

  const instance = useAppSelector((state) =>
    selector(state, typeof id == "number" ? id || 0 : 0),
  )
  if (isNew) {
    return { trait: id || "", isNew }
  } else {
    if (instance) {
      return { trait: getter(instance), isNew }
    } else {
      return { trait: "", isNew }
    }
  }
}


interface ChoiceTraitOption {
  value: number | string
  label: string
}

interface ChoiceTraitResult {
  trait: string
}

// export function useChoiceTrait(
//   options: ChoiceTraitOption[],
//   value?: number | string | null,
// ): ChoiceTraitResult {
//   const matchingOption = options.find((option) => option.value === value)
//   if (matchingOption) {
//     return { trait: matchingOption.label }
//   } else {
//     return { trait: "Unknown" }
//   }
// }

//custome hook to ernder toast messages

export function useCustomToast() {
  const bg = {
    success: "#039855",
    error: "#FF474C",
    info: "#326CB0",
    warning: "#FFBF00",
  }
  const toast = useToast();

  const showToast = ({ status, description, bgColor, icon, isClosable = true, duration = 3000, onCloseCallback }: ToastOptions) => {
    return toast({
      position: 'top',
      duration: duration,
      isClosable: isClosable,
      onCloseComplete: onCloseCallback,
      render: ({ onClose }) => (
        <Box
          color="white"
          p={3}
          bg={bgColor || bg[status]}  // Default colors for status
          borderRadius="md"
          display="flex"
          alignItems="center"
          boxShadow="lg"
          zIndex={10000}
        >
          {icon || (status !== 'success' ? <FaExclamationCircle /> : <FaCheckCircle />)}
          <Box flex="1" ml={3}>
            <div>{description}</div>
          </Box>
          {isClosable &&
            <CloseButton onClick={() => {
              onClose(); // Close the toast
              // if (onCloseCallback) onCloseCallback(); // Call the callback if provided
            }} />}
        </Box>
      ),
    });
  };

  // Adding updateToast function to update existing toast
  const updateToast = (id: number, options: ToastOptions) => {
    toast.update(id, {
      duration: options.duration,
      onCloseComplete: options.onCloseCallback,
      ...options,
      render: ({ onClose }) => (
        <Box
          color="white"
          p={3}
          bg={options.bgColor || bg[options.status]} // Maintain consistent styling
          borderRadius="md"
          display="flex"
          alignItems="center"
          boxShadow="lg"
          zIndex={10000}
        >
          {options.icon || (options.status !== 'success' ? <FaExclamationCircle /> : <FaCheckCircle />)}
          <Box flex="1" ml={3}>
            <div>{options.description}</div>
          </Box>
          {options.isClosable && <CloseButton onClick={() => {
            onClose(); // Close the toast
            // if (options.onCloseCallback) options.onCloseCallback(); // Call the callback if provided
          }} />}
        </Box>
      ),
    });
  };

  const clearToast = (id: string | number) => {
    toast.close(id); // Close the toast by ID
  };

  return { showToast, updateToast, clearToast };
};



//custome hook for network connectivity check
export const useConfigCheck = () => {
  const context = useContext(ConfigContext)
  if (!!!context) {
    throw Error("useConfigCheck must be inside of ConfigProvider")
  }
  return context
}


export interface RefPanel extends HTMLDivElement {
  triggerValidation: () => Promise<boolean>;
  resetForm: () => void;
  scrollIntoView(options?: ScrollIntoViewOptions): void;
}

type RefMap = { [key: string]: RefPanel | null | undefined }
type SetRef = (name: string) => (element: RefPanel | HTMLElement | null) => void
type ScrollTo = (name: string) => void


export const useScrollTo = (): [SetRef, ScrollTo, RefMap] => {
  const refs = useRef<RefMap>({})

  const setRef: SetRef = useCallback(
    (name) => (element) => {
      // refs.current[name] = element
      if (element) {
        refs.current[name] = element as RefPanel
      } else {
        refs.current[name] = null
      }
    },
    [],
  )

  const scrollTo: ScrollTo = useCallback((name) => {
    if (refs.current[name]) {
      refs.current[name]?.scrollIntoView({ behavior: "smooth" })
    }
  }, [])

  return [setRef, scrollTo, refs.current]
}



// cutome hook for showing response messages
export type AlertStatus = "error" | "success" | "warning" | "info"
interface UseMessageBoxReturn {
  showAlert: boolean
  alertStatus: AlertStatus
  alertMessage: string | string[] | undefined
  errorDetail: any
  setAlert: (
    status: AlertStatus,
    message: string | string[],
    error?: any,
  ) => void
  clearAlert: () => void
}

export function useMessageBox(): UseMessageBoxReturn {
  const [showAlert, setShowAlert] = useState(false)
  const [alertStatus, setAlertStatus] = useState<AlertStatus>("error")
  const [alertMessage, setAlertMessage] = useState<
    string | string[] | undefined
  >(undefined)
  const [errorDetail, setErrorDetail] = useState<any>(undefined)

  const setAlert = (
    status: AlertStatus,
    message: string | string[],
    error?: any,
  ) => {
    setAlertStatus(status)
    setAlertMessage(message)
    setErrorDetail(error)
    setShowAlert(true)
  }

  const clearAlert = () => {
    setShowAlert(false)
    setAlertMessage(undefined)
    setErrorDetail(undefined)
  }

  // useEffect(() => {
  //   if (showAlert) {
  //     const timer = setTimeout(() => {
  //       clearAlert() // Automatically clear the alert after 5 seconds
  //     }, 5000)

  //     return () => clearTimeout(timer) // Clear timeout if component unmounts or alert changes
  //   }
  // }, [showAlert, clearAlert])

  return {
    showAlert,
    alertStatus,
    alertMessage,
    errorDetail,
    setAlert,
    clearAlert,
  }
}


interface uploadFileProps {
  files: File[] | string
  draftId: number
  folderType: string
}

export function useUploadFileToS3() {
  const [getPresignedUrl] = useGetPresignedUrlMutation();
  const [uploadFileToS3] = useUploadFileToS3Mutation();

  const upload = async ({ files, draftId, folderType }: uploadFileProps) => {
    try {
      // Generate metadata for files or signature
      const fileObjects = Array.isArray(files)
        ? files.map((file, index) => ({
          id: index + 1,
          object_key: `${draftId}/${folderType}/${draftId}_${index}_${file.name}`,
          mime_type: file.type,
        }))
        : [
          // If it's a single signature 
          {
            id: 1,
            object_key: `${draftId}/${folderType}/${draftId}.png`,
            mime_type: (await (await fetch(files)).blob()).type,
          },
        ];

      const presignedUrls = await getPresignedUrl({ object_keys: fileObjects }).unwrap();

      if (!presignedUrls || presignedUrls.length === 0) {
        throw new Error('No presigned URLs received');
      }

      // Upload files or signature
      if (Array.isArray(files)) {
        await Promise.all(
          files.map((file, index) =>
            uploadFileToS3({ url: presignedUrls[index].url, file }).unwrap()
          )
        );
      } else {
        const blob = await (await fetch(files)).blob();
        await uploadFileToS3({ url: presignedUrls[0].url, file: blob }).unwrap();
        return presignedUrls[0].object_key
      }
    } catch (error) {
      console.error('Error uploading files:', error);
      throw new Error("Error uploading files")
    }
  };

  return { upload };
}




//debouncer

export function useDebounce(value: string, delay: number): string {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}