import { KnownIcon } from './widgets/KnownIcon';
import { Box, Flex, Heading, HStack, Text } from '@chakra-ui/react';

interface CardViewProps {
    title: string
    helper: string
    onClick?: () => void;
}

export function CardView({ title, helper, onClick }: CardViewProps) {
    return (
        <Flex
            p={4}
            w="full"
            h="120px"
            bgColor="#EBEEFF"
            borderRadius="6px"
            onClick={onClick}
            cursor="pointer"
            className="scale-effect"
            justifyContent={"space-between"}
        >
            <HStack w="full" alignItems={"center"} justifyContent={"space-between"}>
                <Box>
                    <Heading fontSize={"20px"} fontWeight={600} color={"#0E1628"} lineHeight={"1.5rem"} mb={2}>{title}</Heading>
                    <Text fontWeight={400} color={"#666E82"}>{helper}</Text>
                </Box>
                <Box>
                    <KnownIcon name='link' boxSize={"1.5rem"} />
                </Box>
            </HStack>
        </Flex >
    )
}
