import React from 'react';
import { Stack, StackProps } from '@chakra-ui/react';

interface ContainerProps extends StackProps {
    condensed?: boolean
    direction?: "row" | "column" | "row-reverse" | "column-reverse";
    children: React.ReactNode | React.ReactNode[]
}


export function Container({
    direction = "row",
    children,
    condensed,
    ...stackProps
}: ContainerProps) {
    return (
        // <Stack flexDirection={direction} w={condensed ? { base: "88%", sm: "85%", md: "80%" } : { base: "98%", sm: "95%", md: "90%" }} maxW={"1440px"} h="full" p={2} gap={[2, 4]} mx="auto" alignItems={"flex-start"} mb={!condensed ? 10 : 0}  {...stackProps}>
        <Stack flexDirection={direction} w={{ base: "98%", sm: "95%", md: "90%" }} maxW={"1440px"} h="full" p={2} gap={[2, 4]} mx="auto" alignItems={"flex-start"}  {...stackProps}>
            {children}
        </Stack>
    )
}
