import { PuffLoader } from "react-spinners";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useConfigCheck } from "../../app/hooks";
import { ErrorBox } from "../../components/ErrorBox";
import { TicketCard } from "../../components/TicketCard";
import { DetailHeading } from "../../components/DetailHeading";
import { useListingPaginatedTicketDataQuery } from "./ticketsApi";
import { CustomButton } from "../../components/forms/CustomButton";
import { Box, ButtonGroup, Flex, HStack, VStack } from "@chakra-ui/react";

interface LatesTicketsProps {
    limit: number
}

export function LatestTickets({ limit }: LatesTicketsProps) {
    const navigate = useNavigate()
    const { isOnline } = useConfigCheck()
    const { userProfile: { role } = {} } = useConfigCheck() //get role of current user
    const { data, isError, isLoading, error, isSuccess } = useListingPaginatedTicketDataQuery(`paginate=${limit}`)

    const [networkError, setNetworkError] = useState<string>("");
    //set error if there is network issue
    useEffect(() => {
        if (error && 'status' in error && error.status === 'FETCH_ERROR') {
            setNetworkError("You appear to have no or limited connectivity. This information will be visible once connected.");
            window.scrollTo({ top: 0, behavior: "smooth" })
        } else if (isSuccess && data) {
            setNetworkError("");
        }
    }, [isError, isSuccess, data]);
    return (
        <>
            {
                !isLoading ?
                    !isError ?
                        <VStack w="full" gap={4}>
                            <HStack w={"full"} justifyContent={"space-between"} mt={4}>
                                <DetailHeading title="Latest Tickets" />
                                <ButtonGroup>
                                    {
                                        role !== 'customer' && role !== 'driver' &&
                                        <CustomButton
                                            p={4}
                                            title="Approve Time"
                                            onClick={() => navigate("/user/approve-time-entries")}
                                            isDisabled={!isOnline}
                                        />
                                    }
                                    <CustomButton
                                        p={4}
                                        title="View all Tickets"
                                        variant="outline"
                                        onClick={() => navigate("/user/tickets")}
                                        isDisabled={!isOnline}
                                    />
                                </ButtonGroup>
                            </HStack>
                            <VStack w="full" gap={5} minH={"300px"} justifyContent="flex-start" >
                                {
                                    data && data?.tickets.length > 0 ?
                                        data?.tickets.map((ticket) => (
                                            <TicketCard key={ticket.id} ticket={ticket} />
                                        )) :
                                        <Box flex={1} alignContent={"center"}>You do not have any tickets available to view</Box>
                                }
                            </VStack>
                        </VStack>
                        :
                        <ErrorBox show={isError} error={error} message={networkError} />
                    :
                    <Flex w="full" minH="350px" justifyContent="center" alignItems="center" flex={1}>
                        <PuffLoader
                            color="#3D82CE"
                            size={80}
                        />
                    </Flex>
            }
        </>

    )
}


