import { NumberField } from "../../components/forms/fields/NumberField"
import { TextareaField } from "../../components/forms/fields/TextareaField"
import { ServiceAutocomplete } from "../services/ServiceAutocomplete"

interface ServicesFormProps {
}

export function ServicesForm({ }: ServicesFormProps) {
  return (
    <>
      <ServiceAutocomplete
        label="Name"
        name="service"
        registerOptions={{ required: "Please Enter Service" }}
      />
      <NumberField
        label="Quantity"
        name="quantity"
        placeholder="How much or how many"
        registerOptions={{ required: "Please Enter Quantity of Service" }}
      />
      <TextareaField
        label="Description"
        name="description"
        placeholder="Describe what was provided in a few words"
      />
    </>
  )
}
