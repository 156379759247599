import { Box, useMediaQuery } from "@chakra-ui/react";
import { LocationsForm } from "./LocationsForm";
import { forwardRef, useEffect, useRef } from "react";
import { Container } from "../../components/Container";
import { DraftLocationsValues } from "../tickets/tickets";
import { EditForm } from "../../components/forms/EditForm";
import { PanelWrapper } from "../../components/PanelWrapper";
import { OverlayLoader } from "../../components/OverlayLoader";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { TicketResource } from "../../pages/user/TicketDetailPage";
import { CustomButton } from "../../components/forms/CustomButton";
import { WrapperProps } from "../../components/TicketDetailWrapper";
import { saveDraftLocations, selectDraftLocations } from "../draft/draftSlice";
import { selectTicketLocations, updateTicketLocations } from "../tickets/ticketsSlice";
import { RefPanel, useAppDispatch, useAppSelector, useCustomToast } from "../../app/hooks";

interface DraftLocationsPanelProps extends WrapperProps {
    dataresource?: TicketResource
    scrollTo?(name: string): void,
}

export const DraftLocationsPanel = forwardRef<RefPanel, DraftLocationsPanelProps>(
    function DraftLocationsPanel({ scrollTo, isdraft, resourceid }: DraftLocationsPanelProps, ref) {
        const dispatch = useAppDispatch()
        const { showToast } = useCustomToast();
        const [isSmallScreen] = useMediaQuery("(max-width: 468px)")
        const isManualReset = useRef(false);

        //Selector based on draft or ticket
        const selectResource = (isdraft ?
            useAppSelector(selectDraftLocations(Number(resourceid)))
            : useAppSelector(selectTicketLocations(Number(resourceid)))) || {}

        const methods = useForm({ defaultValues: selectResource })
        const { control, handleSubmit, reset, formState: { isSubmitting } } = methods

        // Watch form values to detect changes
        const watchedValues = useWatch({
            control,
            defaultValue: selectResource,
        })
        const hasChanged = JSON.stringify(watchedValues) !== JSON.stringify(selectResource)

        // Save handler based on draft or ticket
        const handleSaveResource = async (data: any) => {
            try {
                if (!isdraft) await dispatch(updateTicketLocations({ id: resourceid, ...data })).unwrap();
                isManualReset.current = true;
                // Scroll to the 'productForm' section after the action is completed
                scrollTo?.("productForm");
            } catch (error) {
                // Catch any errors during the dispatch and handle them
                console.error("Action failed:", error);
                showToast({
                    status: 'error',
                    description: "Something went wrong. Please try again later.",
                });
            }
        };

        //auto save handler for draft page
        useEffect(() => {
            if (isdraft) dispatch(saveDraftLocations({ id: Number(resourceid), ...watchedValues } as DraftLocationsValues));
        }, [watchedValues, resourceid, dispatch, isdraft]);

        // reset form after save event to track any new change in form
        useEffect(() => {
            if (isManualReset.current) {
                reset(selectResource);
            }
            isManualReset.current = false;
        }, [selectResource, reset, methods]);

        return (
            <Box w="full" ref={ref}>
                {isSubmitting && <OverlayLoader />}
                <Container condensed={!isSmallScreen}>
                    <FormProvider {...methods}>
                        <EditForm onSave={handleSubmit(handleSaveResource)}>
                            <PanelWrapper title="Locations">
                                {hasChanged && !isdraft && <CustomButton title="Save" type="submit" variant="outline" w="80px" isLoading={isSubmitting} />}
                                <LocationsForm />
                            </PanelWrapper>
                        </EditForm>
                    </FormProvider>
                </Container>
            </Box>
        )
    })
