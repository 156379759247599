import { compConfigApi } from "../features/compConfig/compConfigApi";
import { Profile, selectSession } from "../features/session/sessionSlice";
import { selectCompConfig } from "../features/compConfig/compConfigSlice";
import { useAppDispatch, useAppSelector, useCustomToast } from "../app/hooks";
import { createContext, ReactNode, useCallback, useEffect, useState, Context, useRef } from "react";


interface ConfigContextProps {
    isOnline: boolean;
    compConfig?: any;
    userProfile?: Profile;
}
interface ConfigResource {
    id: number;
    archived: boolean;
    name: string;
    small_logo: string;
    large_logo: string;
    current_company: boolean;
    company_address: string;
    gst_number: string;
}

interface ConfigProviderProps {
    children: ReactNode
}
export const ConfigContext: Context<ConfigContextProps | undefined> = createContext<ConfigContextProps | undefined>(undefined);
export const ConfigProvider = ({ children }: ConfigProviderProps) => {
    const dispatch = useAppDispatch()
    const { showToast, updateToast } = useCustomToast();

    //get user profile
    const session = useAppSelector(selectSession)
    const userProfile = session?.profile

    // get company config
    const storedCompConfig = useAppSelector(selectCompConfig)

    const [compConfig, setCompConfig] = useState<ConfigResource | null>(null)
    useEffect(() => {
        const fetchConfig = async () => {
            let config;
            const response = await dispatch(compConfigApi.endpoints.getCompConfig.initiate());
            if (response.data) config = response.data[0]
            setConfig(config);
        };
        fetchConfig();
    }, [dispatch]);

    const setConfig = (config: any) => {
        if (config) {
            setCompConfig(config)
        } else {
            setCompConfig(storedCompConfig?.[0])
        }
    }

    //check network connectivity
    const [isOnline, setOnline] = useState<boolean>(navigator.onLine)
    const [previousStatus, setPreviousStatus] = useState<boolean>(navigator.onLine); // Track previous online status
    const [toastId, setToastId] = useState<number | null>(null); // State to hold the toast ID
    const componentMount = useRef<boolean>(false);

    const handleOnline = useCallback(() => setOnline(true), []);
    const handleOffline = useCallback(() => setOnline(false), []);
    useEffect(() => {
        window.addEventListener('online', handleOnline)
        window.addEventListener('offline', handleOffline)
        return () => {
            window.removeEventListener('online', handleOnline)
            window.removeEventListener('offline', handleOffline)
        }
    }, [handleOnline, handleOffline])

    // Show toast on network status change
    useEffect(() => {
        //toast options
        const toastOptions = {
            status: isOnline ? 'success' : 'error' as 'success' | 'error',
            description: isOnline ? 'You’re Online' : 'You’re Offline',
            isClosable: isOnline, // Make it closable only when online
            duration: isOnline ? 3000 : null, // Duration for online toast
            onCloseCallback: isOnline ? () => setToastId(null) : undefined
        }
        //show error toast if user initiating app without network connectivity
        if (!componentMount.current) {
            if (!isOnline) {
                const id = showToast(toastOptions);
                setToastId(Number(id)); // Set the toast ID
            }
            componentMount.current = true
            setPreviousStatus(isOnline);
        } else {
            // Check if the online status has changed
            if (isOnline !== previousStatus) {
                if (toastId) {
                    // Update the existing toast if it exists
                    updateToast(toastId, toastOptions);
                } else {
                    // Show new toast if it doesn't exist
                    const id = showToast(toastOptions);
                    setToastId(Number(id)); // Set the toast ID
                }
                setPreviousStatus(isOnline); // Update the previous status to the current one
            }
        }
    }, [isOnline, previousStatus, toastId, showToast]);

    // Automatically clear toast ID after online status change
    useEffect(() => {
        if (!isOnline || !toastId) return;

        const timer = setTimeout(() => setToastId(null), 5000);
        return () => clearTimeout(timer);
    }, [isOnline, toastId]);

    return (
        <>
            <ConfigContext.Provider value={{ isOnline, userProfile, compConfig }}>
                {children}
            </ConfigContext.Provider>
        </>
    )
}
