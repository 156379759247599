import { useEffect, useState } from "react";
import { FieldProps } from "../../../forms";
import { useFormContext } from "react-hook-form";
import { useAppSelector } from "../../../app/hooks";
import { CustomSelect } from "./CustomeSelectField";
import { OptionType } from "../../../features/tickets/tickets";
import { selectCustomerById } from "../../../features/customers/customersSlice";
import { FormControl, FormErrorMessage, FormHelperText, FormLabel, VStack } from "@chakra-ui/react";

interface ContactNameFieldProps extends FieldProps { }

export function ContactNameField({
    label,
    name,
    helper,
    placeholder,
    registerOptions,
}: ContactNameFieldProps) {
    const { register, formState: { errors }, watch, setValue } = useFormContext();
    const selectedCustomer = watch("customer"); // Track customer value
    const selectedValue = watch(name); // Track current value
    //get contacts based on selected customer
    const contactList = useAppSelector((state) => selectCustomerById(state, selectedCustomer || 0)?.contacts)

    // filter contacts and get contact names
    const suggestions = contactList?.map((contact) => ({
        value: contact.id,
        label: `${contact?.first_name} ${contact?.last_name}`,
    }));

    // Get current selected option
    const [currentOption, setCurrentOption] = useState<OptionType | null>(null)
    const [isNew, setIsNew] = useState<boolean>(false)

    // check selected value is already exist or not
    useEffect(() => {
        if (selectedValue) {
            const exist = suggestions?.find((item) => item.value === selectedValue);
            setIsNew(!exist ? true : false)
            setCurrentOption(exist ? exist : { value: selectedValue, label: selectedValue })
        } else {
            setIsNew(false);
            setCurrentOption(null);
        }
    }, [selectedValue])

    const handleSelect = (selectedOption: OptionType | null) => {
        setValue(name, selectedOption ? selectedOption.value : "");
        setCurrentOption(selectedOption);
    };

    return (
        <FormControl isInvalid={!!errors?.[name]}>
            <VStack w="full" gap="10px" alignItems="flex-start">
                <FormLabel htmlFor={name} color="#455360" >{label}</FormLabel>
                <CustomSelect
                    name={name}
                    value={currentOption}
                    options={suggestions}
                    register={register}
                    registerOptions={registerOptions}
                    errors={errors}
                    placeholder={placeholder}
                    handleChange={handleSelect}
                    isClearable={true}
                    isSearchable={true}
                    icon='blueUser'
                    isNew={isNew}
                    isDisabled={!selectedCustomer}
                />
                {helper && <FormHelperText>{helper}</FormHelperText>}
                <FormErrorMessage>{errors?.[name]?.message?.toString()}</FormErrorMessage>
            </VStack>
        </FormControl >
    )
}


