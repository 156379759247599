import { Text } from "@chakra-ui/react";
import { useAppSelector } from "../../app/hooks";
import { selectActivityById } from "../activities/activitySlice";

interface ActivityTraitProps extends ChoiceTraitProps { }

export function ActivityTrait({ choice }: ActivityTraitProps) {
  const activity = useAppSelector(state => selectActivityById(state, Number(choice)))?.name;
  return <Text>{activity}</Text>
}
